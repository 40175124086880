import React from 'react';

export interface ItemProps {
  currentPage?: number;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  onPageChanged: (event: any) => void;
  children?: React.ReactNode;
  [key: string]: any;
}

const PaginationItem = ({
  active = false,
  disabled = false,
  className = '',
  onPageChanged,
  children,
  ...props
}: ItemProps): JSX.Element => {
  return (
    <li className={`page ${className ? className : ''}`}>
      <button
        aria-hidden="true"
        disabled={disabled}
        className={`page-link${active ? ' active' : ''}`}
        onClick={(e) => onPageChanged(e)}
        aria-label="page link"
        {...props}>
        {children}
      </button>
    </li>
  );
};

export default PaginationItem;
