import { IForms } from '../models';

export const FORMS: IForms = {
  other: {
    sales: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'sales-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'country',
        type: 'dropdown',
        id: 'salesCountries',
        label: 'form.country.label',
        placeholder: 'form.country.placeholder',
        className: 'column large-6 medium-8 small-12',
        required: true,
        option1: 'opt.widgetPage.countryMandatoryDefault',
      },
      {
        name: 'products',
        type: 'select',
        id: 'salesProducts',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        showOnly: 'country',
        required: true,
        option1: 'opt.locatorPage.productListDefault',
      },
    ],
    service: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'service-address',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        label: 'form.location.label',
        geolocation: true,
        required: true,
      },
      {
        name: 'country',
        type: 'dropdown',
        id: 'service-countries',
        className: 'column large-6 medium-8 small-12',
        label: 'form.country.label',
        placeholder: 'form.country.placeholder',
        required: true,
        option1: 'opt.widgetPage.countryMandatoryDefault',
      },
      {
        name: 'products',
        type: 'select',
        id: 'service-products',
        className: 'column large-6 medium-8 small-12',
        label: 'form.products.label',
        placeholder: 'form.product.placeholder',
        required: true,
        option1: 'opt.locatorPage.productMandatoryDefault',
        showOnly: 'country',
      },
      {
        name: 'carrier',
        type: 'dropdown',
        id: 'service-carrier',
        className: 'column large-6 medium-8 small-12',
        label: 'form.carrier.label',
        placeholder: 'form.carrier.placeholder',
        showOnly: 'products',
        required: true,
        option1: 'opt.locatorPage.carrierMandatoryDefault',
      },
    ],
  },
  cn_zh: {
    sales: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'sales-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'select',
        id: 'sales-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        required: true,
        option1: 'opt.locatorPage.productListDefault',
      },
    ],
    service: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'service-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'select',
        id: 'service-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        source: ['products', 'properties', 'propertyArray'],
        required: true,
        option1: 'form.product.defaultOption',
      },
    ],
  },
  default: {
    sales: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'sales-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'select',
        id: 'sales-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        required: true,
        option1: 'opt.locatorPage.productListDefault',
      },
    ],
    service: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'service-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'select',
        id: 'service-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        source: ['products', 'properties', 'propertyArray'],
        required: true,
        option1: 'form.product.defaultOption',
      },
      {
        name: 'carrier',
        type: 'dropdown',
        id: 'service-carrier',
        className: 'column large-6 medium-8 small-12',
        label: 'form.carrier.label',
        placeholder: 'form.carrier.placeholder',
        showOnly: 'products',
        source: ['carrierMap'],
        required: true,
        option1: 'form.carrier.defaultOption',
      },
    ],
  },
};

export const MOBILE_FORMS: IForms = {
  other: {
    sales: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'sales-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'country',
        type: 'dropdown',
        id: 'salesCountries',
        label: 'form.country.label',
        placeholder: 'form.country.placeholder',
        className: 'column large-6 medium-8 small-12',
        required: true,
        option1: 'opt.widgetPage.countryMandatoryDefault',
      },
      {
        name: 'products',
        type: 'dropdown',
        id: 'salesProducts',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        showOnly: 'country',
        required: true,
        option1: 'opt.locatorPage.productListDefault',
      },
    ],
    service: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'service-address',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        label: 'form.location.label',
        geolocation: true,
        required: true,
      },
      {
        name: 'country',
        type: 'dropdown',
        id: 'service-countries',
        className: 'column large-6 medium-8 small-12',
        label: 'form.country.label',
        placeholder: 'form.country.placeholder',
        required: true,
        option1: 'opt.widgetPage.countryMandatoryDefault',
      },
      {
        name: 'products',
        type: 'dropdown',
        id: 'service-products',
        className: 'column large-6 medium-8 small-12',
        label: 'form.products.label',
        placeholder: 'form.product.placeholder',
        required: true,
        option1: 'opt.locatorPage.productMandatoryDefault',
        showOnly: 'country',
      },
      {
        name: 'carrier',
        type: 'dropdown',
        id: 'service-carrier',
        className: 'column large-6 medium-8 small-12',
        label: 'form.carrier.label',
        placeholder: 'form.carrier.placeholder',
        showOnly: 'products',
        required: true,
        option1: 'opt.locatorPage.carrierMandatoryDefault',
      },
    ],
  },
  cn_zh: {
    sales: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'sales-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'dropdown',
        id: 'sales-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        required: true,
        option1: 'opt.locatorPage.productListDefault',
      },
    ],
    service: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'service-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'dropdown',
        id: 'service-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        source: ['products', 'properties', 'propertyArray'],
        required: true,
        option1: 'form.product.defaultOption',
      },
    ],
  },
  default: {
    sales: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'sales-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'dropdown',
        id: 'sales-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        required: true,
        option1: 'opt.locatorPage.productListDefault',
      },
    ],
    service: [
      {
        name: 'search',
        type: 'autocomplete',
        id: 'service-address',
        label: 'form.location.label',
        className: 'column large-6 medium-8 small-12 txt-box',
        placeholder: 'form.location.placeholder',
        geolocation: true,
        required: true,
      },
      {
        name: 'products',
        type: 'dropdown',
        id: 'service-products',
        label: 'form.products.label',
        placeholder: 'form.products.placeholder',
        className: 'column large-6 medium-8 small-12',
        source: ['products', 'properties', 'propertyArray'],
        required: true,
        option1: 'form.product.defaultOption',
      },
      {
        name: 'carrier',
        type: 'dropdown',
        id: 'service-carrier',
        className: 'column large-6 medium-8 small-12',
        label: 'form.carrier.label',
        placeholder: 'form.carrier.placeholder',
        showOnly: 'products',
        source: ['carrierMap'],
        required: true,
        option1: 'form.carrier.defaultOption',
      },
    ],
  },
};
