import React from 'react';
import './stores.scss';

const Stores = ({ className, children, ...props }: { className?: string; children: React.ReactNode; [key: string]: any }): JSX.Element => (
  <ul className={className} {...props}>
    {children}
  </ul>
);

export default Stores;
