import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const DETECTION_OPTIONS = {
  order: ['navigator', 'localStorage', 'sessionStorage', 'cookie', 'htmlTag', 'path'],
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
  cookieOptions: { path: '/', sameSite: 'strict', httpOnly: true, secure: true },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: false,
    detection: DETECTION_OPTIONS,
    contextSeparator: '-',
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    react: {
      wait: true,
      useSuspense: false,
    },
    backend: {
      loadPath: (langs: string[], namespaces: string[]) => {
        return `/locales/${namespaces[0]}s-${langs[0].replace('-', '_')}.json`;
      },
      allowMultiLoading: false,
      overrideMimeType: false,
      backendOptions: [
        {
          expirationTime: 30 * 24 * 60 * 60 * 1000,
        },
      ],
    },
  });

export default i18n;
