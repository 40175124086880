import { GeocoderType, GeocoderConstructorOptions, LookupOptions, GeocoderResponseType, Coordinate } from '../map-models';
class Geocoder {
  private geocoder: GeocoderType;
  constructor() {
    this.geocoder = new mapkit.Geocoder();
  }

  reverseGeocoding = (
    coordinate: Coordinate,
    options?: Pick<GeocoderConstructorOptions, 'language'>,
  ): Promise<GeocoderResponseType | { [key: string]: any } | null> => {
    return new Promise((resolve, reject) => {
      this.geocoder.reverseLookup(
        coordinate,
        (error, data) => {
          if (error) {
            reject(error);
          }
          resolve(data);
        },
        options,
      );
    });
  };

  forwardGeocoding = (place: string, options?: LookupOptions): Promise<GeocoderResponseType | { [key: string]: any } | null> => {
    return new Promise((resolve, reject) => {
      this.geocoder.lookup(
        place,
        (error, data) => {
          if (error) {
            reject(error);
          }
          resolve(data);
        },
        options,
      );
    });
  };

  cancel = (geocoder: GeocoderType, id: number): boolean => {
    return geocoder.cancel(id);
  };
}
export const geocoder = new Geocoder();
