import React, { Fragment, memo, useEffect } from 'react';
import ErrorBoundary from '../../components/error-boundary';
import LocalNav from '../../components/localnav';
import { EVENT_MAP } from '../../constants';
import { ICountry, IRegion, REGIONS } from '../../constants/countries';
import Layout from '../../layout';
import { trackEvent } from '../../services/utility';
import './country-page.scss';

const RegionHeadline = memo(function RegionHeadline({ name, label, first }: { name: string; label: string; first?: boolean }): JSX.Element {
  return (
    <h2 className={`region-name${first ? ' first' : ''}`} id={name}>
      {label}
    </h2>
  );
});

const CountryLink = memo(function CountryLink({
  link,
  lang,
  label,
  ...props
}: {
  link: string;
  lang: string;
  label: string;
  [key: string]: any;
}) {
  const handleClick = (event: any) => {
    trackEvent(EVENT_MAP.CLICK, {
      type: 'country change link clicked',
      link_text: event.target.innerText,
      dest_url: event.target.href,
    });
  };
  return (
    <li className="country">
      <a onClick={handleClick} href={link} lang={lang} {...props}>
        {label}
      </a>
    </li>
  );
});

const Region = (region: IRegion) => (
  <section className="region">
    <RegionHeadline name={region.name} first={region.first} label={region.label} />
    <ul className="countries list list-nobullet">
      {region.countries.map((country: ICountry, index: number) => (
        <CountryLink key={index} lang={country.locale} dir={country.isRTL ? 'rtl' : ''} label={country.label} link={country.link} />
      ))}
    </ul>
  </section>
);

const CountryPage = (): JSX.Element => {
  useEffect(() => {
    if (__satelliteLoaded) {
      _applemd.page.clean_url = window.location.href;
      _applemd.page.locale = 'en-US';
      _applemd.page.support_site = `locate.us`;
      _applemd.page.content_subtype = 'find locations::choose-country-selector';
      trackEvent(EVENT_MAP.PAGE_LOAD);
    }
  }, []);

  return (
    <Fragment>
      <ErrorBoundary>
        <LocalNav title="Choose Your Country or Region" />
        <Layout>
          <h1 className="visuallyhidden">Choose Your Country or Region</h1>
          <div id="main" className="main" data-page-type="">
            {REGIONS.map((region, index) => (
              <Region key={index} {...region} first={index === 0} />
            ))}
          </div>
        </Layout>
      </ErrorBoundary>
    </Fragment>
  );
};

export default memo(CountryPage);
