import React from 'react';
import './selector.scss';
export interface SelectorGroupProps {
  id: string;
  withGutters?: boolean;
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
}

const SelectorGroup = ({ id, withGutters = false, className = '', children, ...props }: SelectorGroupProps): JSX.Element => {
  const classes = [withGutters ? 'form-selector-group-withgutters' : 'form-selector-group', className].filter(Boolean).join(' ');
  return (
    <ul id={id} className={classes} {...props}>
      {children}
    </ul>
  );
};

export default SelectorGroup;
