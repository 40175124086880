import React, { memo } from 'react';
import { ReactComponent as Automobile } from '../../assets/automobile.svg';
import { ReactComponent as Car } from '../../assets/car.svg';
import { ReactComponent as Cycle } from '../../assets/cycle.svg';
import { ReactComponent as Person } from '../../assets/person.svg';
import { ReactComponent as AirPods } from '../../assets/products/airpod.svg';
import { ReactComponent as AirPodsMax } from '../../assets/products/airpodsmax.svg';
import { ReactComponent as AirPodsPro } from '../../assets/products/airpodspro.svg';
import { ReactComponent as AllProducts } from '../../assets/products/allproducts.svg';
import { ReactComponent as AppleTV } from '../../assets/products/appletv.svg';
import { ReactComponent as Beats } from '../../assets/products/beats.svg';
import { ReactComponent as Email } from '../../assets/products/email.svg';
import { ReactComponent as HomePod } from '../../assets/products/homepod.svg';
import { ReactComponent as IPad } from '../../assets/products/ipad.svg';
import { ReactComponent as IPhone } from '../../assets/products/iphone.svg';
import { ReactComponent as IPod } from '../../assets/products/ipod.svg';
import { ReactComponent as Mac } from '../../assets/products/mac.svg';
import { ReactComponent as AppleWatch } from '../../assets/products/watch.svg';
import { ReactComponent as AppleWatchHermes } from '../../assets/products/watchhermes.svg';
import { ReactComponent as AppleWatchNike } from '../../assets/products/watchnike.svg';
import { ReactComponent as Public_Transport } from '../../assets/public_transport.svg';
import { ReactComponent as VisionPro } from '../../assets/products/visionpro.svg';
import './svg-icon.scss';

const SvgIcon = ({
  product,
  className,
  ariaLabel = '',
  ariaHidden = false,
}: {
  product: string;
  className?: string;
  ariaLabel?: string;
  ariaHidden?: boolean;
}): JSX.Element => {
  const classes = ['svg-icon', className].filter(Boolean).join(' ');
  switch (product) {
    case 'iphone':
      return <IPhone title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'ipad':
      return <IPad title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'ipod':
      return <IPod title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'airpods':
      return <AirPods title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'airpodspro':
      return <AirPodsPro title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'airpodsmax':
      return <AirPodsMax title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'beats':
      return <Beats title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'mac':
      return <Mac title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'appletv':
      return <AppleTV title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'homepod':
      return <HomePod title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'watch':
    case 'watchedition':
      return <AppleWatch title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'watchnike':
      return <AppleWatchNike title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'watchhermes':
      return <AppleWatchHermes title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'allproducts':
      return <AllProducts title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'email':
      return <Email title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'cycle':
      return <Cycle title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'personal':
      return <Car title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'walking':
      return <Person title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'public_transport':
      return <Public_Transport title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'automobile':
      return <Automobile title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    case 'visionpro':
      return <VisionPro title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
    default:
      return <IPhone title={ariaLabel} aria-hidden={ariaHidden} className={classes} />;
  }
};

export default memo(SvgIcon);
