import React, { forwardRef } from 'react';
import useUuid from '../../../hooks/use-uuid';
import './toggle.scss';

export interface ToggleProps {
  id?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  [key: string]: any;
}

const Toggle = (
  { id, className = '', children, disabled, onChange, ...props }: ToggleProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => {
  const uuid = useUuid();
  const genId = id || uuid;
  const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChange && onChange(event);
  };
  return (
    <div className="form-toggle">
      <button id={genId} ref={ref} disabled={disabled} onClick={handleChange} className={`link ${className}`} {...props}>
        {children}
        <span className="form-toggle-indicator"></span>
      </button>
    </div>
  );
};

export default forwardRef(Toggle);
