import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import LocaleSelector from '../components/locale-selector';
import { StoreContext } from '../store/store';
import './layout.scss';
export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { store } = useContext(StoreContext);
  const { global } = store;
  const { country } = useParams<{ [key: string]: any }>();

  return (
    <div className="layout text-center">
      <div className="layout__viewport" role="main">
        {children}
      </div>
      <div className="layout__spacer">
        {global && global.langs.length > 1 && <LocaleSelector countryCode={country} langs={global.langs} />}
      </div>
    </div>
  );
};

export default Layout;
