import { Actions, ActionType, IState } from './model';

const reducer = (state: IState, action: ActionType): IState => {
  switch (action.type) {
    case Actions.CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case Actions.RESET_SEARCH:
      return { ...state, locale: action.payload, results: undefined };
    case Actions.RESET_RESULTS:
      return { ...state, results: undefined, errors: undefined };
    case Actions.FETCH_RESOURCES:
      return { ...state, global: action.payload.global, channels: action.payload.channels, results: undefined };
    case Actions.FETCH_RESOURCES_FAILURE:
      return { ...state, errors: action.payload };
    case Actions.FETCH_STORE_RESULTS:
      return { ...state, results: action.payload, errors: undefined };
    case Actions.FETCH_STORE_RESULTS_FAILURE:
      return { ...state, results: undefined, errors: action.payload };
    case Actions.LOADING:
      return { ...state, loading: action.payload };
    case Actions.LOADING_RESULTS:
      return { ...state, fetching_results: action.payload };
    case Actions.FETCH_MAPKIT_TOKEN:
      return { ...state, token: action.payload };
    case Actions.FETCH_MAPKIT_TOKEN_ERROR:
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

export default reducer;
