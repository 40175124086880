import { GlobalMatrix } from '../models/api-interface';
import { ChannelMap } from '../models';
import { StoreResponse } from '../models/store-models';

export interface IState {
  locale?: string;
  global?: GlobalMatrix;
  channels: ChannelMap;
  results?: StoreResponse;
  loading: boolean;
  fetching_results: boolean;
  token?: string;
  errors?: string;
  [key: string]: any;
}

export interface IStoreContext {
  store: IState;
  dispatch: React.Dispatch<ActionType>;
}

export enum Actions {
  CHANGE_LOCALE = 'CHANGE_LOCALE',
  RESET_SEARCH = 'RESET_SEARCH',
  RESET_RESULTS = 'RESET_RESULTS',
  FETCH_RESOURCES = 'FETCH_RESOURCES',
  FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE',
  LOADING = 'LOADER',
  LOADING_RESULTS = 'LOADING_RESULTS',
  FETCH_STORE_RESULTS = 'FETCH_STORE_RESULTS',
  FETCH_STORE_RESULTS_FAILURE = 'FETCH_STORE_RESULTS_FAILURE',
  FETCH_MAPKIT_TOKEN = 'FETCH_MAPKIT_TOKEN',
  FETCH_MAPKIT_TOKEN_ERROR = 'FETCH_MAPKIT_TOKEN_ERROR',
}

export interface ActionType {
  type: Actions;
  payload?: any;
}

export interface GlobalMatrixPayload {
  lang: string;
  countryCode: string;
}

export interface SearchPayload {
  lang: string;
  countryCode: string;
  channel: string;
  productId: string;
  latitude: number;
  longitude: number;
  product: string;
  formattedAddress: string;
  maxRadius?: number;
  maxResults?: number;
  storeType?: string;
  carriers?: string;
  repairType?: string;
}
