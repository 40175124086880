/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component, ErrorInfo, ReactNode } from 'react';
import './error-boundary.scss';
interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };
  static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
    return { hasError: true, error, errorInfo };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Error detected: stack trace', error);
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container">
          <div className="callout">
            <h4 className="callout-title typography-callout">Error Occured.</h4>
            <p className="callout-copy">Please contact the administrator for the issue.</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
