import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import './no-results.scss';

const NoResult: React.ForwardRefRenderFunction<HTMLDivElement, any> = (props, ref): JSX.Element => {
  const { t } = useTranslation();
  const { channel } = props;
  return (
    <div ref={ref} className="no-result">
      <h2 className="title">{t('page.results.noResultsTitle')}.</h2>
      <p className="description">{t(`page.results.noResults${channel === 'sales' ? 'Sales' : 'Service'}Msg`)}</p>
    </div>
  );
};

export default memo(forwardRef(NoResult));
