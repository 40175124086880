import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../models/api-interface';
import { Sanitized } from '@idms/react-sanitized';
import './badge-info.scss';

const BadgeInfo = ({ code }: Badge): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="badge-info">
      <Sanitized tagName="h3" className="store-type uppercase" html={t(`page.results.badges.${code}`)} />
      <Sanitized tagName="p" className="store-desc" html={t(`page.results.badges.${code}Description`)} />
    </div>
  );
};

export default memo(BadgeInfo);
