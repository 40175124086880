import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ErrorBoundary from '../../components/error-boundary';
import Loader from '../../components/loader';
import LocalNav from '../../components/localnav';
import Tile from '../../components/tile';
import { EVENT_MAP, ORDERED_CHANNEL_ARRAY, NON_PRODUCT_CHANNELS } from '../../constants';
import { LOCALE_MAPPING } from '../../constants/locale-mapping';
import Layout from '../../layout';
import { trackEvent } from '../../services/utility';
import { fetchResources, populateResources } from '../../store/actions';
import { StoreContext } from '../../store/store';
import './home-page.scss';

const HomePage = (): JSX.Element => {
  const { store, dispatch } = useContext(StoreContext);
  const { global, channels, loading } = store;
  const { lang } = useParams<{ [key: string]: any }>();
  const { i18n, t, ready } = useTranslation();
  const history = useHistory();

  const selectedLocale = useMemo(() => {
    const browserLocale = (document.getElementById('locale') as HTMLInputElement).value || '';
    return browserLocale;
  }, []);
  const langs = selectedLocale.toLowerCase().split('-');

  useEffect(() => {
    if (selectedLocale) {
      const pathname = history.location.pathname;
      const path = LOCALE_MAPPING[selectedLocale];
      if (pathname !== path) {
        history.push(path);
      }
    }
    if (globalObject && globalObject.response && globalObject.response.length > 0) {
      populateResources(dispatch, globalObject.response, { lang: langs[0], countryCode: langs[1] });
    } else {
      if (selectedLocale) {
        if (!global) {
          fetchResources(dispatch, { lang: langs[0], countryCode: langs[1] });
        } else if (global.countryCode !== langs[1].toLowerCase()) {
          fetchResources(dispatch, { lang: langs[0], countryCode: langs[1] });
        }
      }
    }
    i18n.changeLanguage(selectedLocale);
    if (__satelliteLoaded) {
      _applemd.page.clean_url = window.location.href;
      _applemd.page.locale = selectedLocale.toLowerCase();
      _applemd.page.support_site = `locate.${langs[1].toLowerCase()}`;
      _applemd.page.content_subtype = 'find locations::landing';
      trackEvent(EVENT_MAP.PAGE_LOAD);
    }
  }, []);

  return (
    <Fragment>
      {ready && (
        <ErrorBoundary>
          <LocalNav stickNav border collapseAt="small" initialState="default" title={t('page.home.localNavTitle')} />
          <Layout>
            {loading && (
              <div className="loading-container">
                <Loader ariaLabel="Loading home page" />
              </div>
            )}
            {!loading && global && (
              <div className="grid grid-no-gutters">
                <h1 className="visuallyhidden">{t('page.home.localNavTitle')}</h1>
                {ORDERED_CHANNEL_ARRAY.map((channel, index) => (
                  <Fragment key={channel}>
                    {channels &&
                      channels[channel] &&
                      ((channels[channel].options.products || []).length > 0 ||
                        (channels[channel].options.country || []).length > 0 ||
                        NON_PRODUCT_CHANNELS.includes(channel)) && (
                        <div className="grid-item large-span-12 widget-item">
                          <Tile
                            {...channels[channel]}
                            path={history.location.pathname}
                            channel={channel}
                            locale={selectedLocale}
                            countryCode={langs[1]}
                            image={global.images[`${channel}ImageUrl`] || channel}
                            imagePosition={index % 2 !== 0 ? 'left' : 'right'}></Tile>
                        </div>
                      )}
                  </Fragment>
                ))}
              </div>
            )}
          </Layout>
        </ErrorBoundary>
      )}
    </Fragment>
  );
};

export default HomePage;
