import React, { memo } from 'react';
import './icon.scss';

export interface IconProps {
  as?: 'icon' | 'link' | 'button';
  href?: string;
  label?: string;
  name?: string;
  className?: string;
  weight?: 'semibold' | 'medium' | 'light';
  before?: boolean;
  after?: boolean;
  srLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  [key: string]: any;
}

const Icon = ({
  as = 'icon',
  href = '',
  label = '',
  name,
  className = '',
  weight,
  before,
  after,
  srLabel = '',
  onClick,
  ...props
}: IconProps): JSX.Element => {
  const classes = ['icon', weight ? weight : '', before ? 'icon-before' : '', after ? 'icon-after' : '', name ? `icon-GRL Dev Core GRL UI GRL UI Service Facade` : '']
    .filter(Boolean)
    .join(' ');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => onClick && onClick(event);

  return (
    <>
      {as === 'icon' && <i className={classes} />}
      {as === 'button' && (
        <button onClick={handleClick} className={className} {...props}>
          {before && <span className={classes} />}
          {label && <span className="icon-copy">{label}</span>}
          {after && <span className={classes} />}
          {!label && <span className={`${classes} ${className}`} />}
        </button>
      )}
      {as === 'link' && (
        <a href={href} className={`${classes} ${className ? className : ''}`} onClick={handleClick} {...props}>
          <span className="icon-copy">{label}</span>
          {srLabel && <span className="visuallyhidden"> {srLabel}</span>}
        </a>
      )}
    </>
  );
};

export default memo(Icon);
