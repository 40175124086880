import { useEffect } from 'react';

export default function useKeyPress(key: string, action: any) {
  useEffect(() => {
    function onKeyup(event: any) {
      if (event.key === key) {
        action(event);
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);
}
