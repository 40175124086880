export const LOCALE_MAPPING: { [key: string]: string } = {
  'en-US': '/',
  'en-GB': '/uk/en/',
  'id-ID': '/id/id/',
  'en-EUROPE': '/europe/en/',
  'en-ASIA': '/asia/en/',
  'en-LAC': '/lac/en/',
  'es-LAC': '/lac/es/',
  'fr-LAC': '/lac/fr/',
  'en-AMEI': '/amei/en/',
  'fr-AMEI': '/amei/fr/',
  'fr-DZ': '/dz/fr/',
  'en-AM': '/am/en/',
  'en-BH': '/bh/en/',
  'en-BW': '/bw/en/',
  'fr-CM': '/cm/fr/',
  'fr-CF': '/cf/fr/',
  'fr-CI': '/ci/fr/',
  'en-EG': '/eg/en/',
  'en-GW': '/gw/en/',
  'fr-GN': '/gn/fr/',
  'fr-GQ': '/gq/fr/',
  'en-IN': '/in/en/',
  'en-IL': '/il/en/',
  'en-JO': '/jo/en/',
  'en-KE': '/ke/en/',
  'en-KW': '/kw/en/',
  'fr-LB': '/lb/fr/',
  'en-LY': '/ly/en/',
  'fr-LY': '/ly/fr/',
  'fr-MG': '/mg/fr/',
  'fr-ML': '/ml/fr/',
  'fr-MU': '/mu/fr/',
  'fr-MA': '/ma/fr/',
  'pt-MZ': '/mz/pt/',
  'en-NA': '/na/en/',
  'fr-NA': '/na/fr/',
  'fr-NE': '/ne/fr/',
  'en-NG': '/ng/en/',
  'en-OM': '/om/en/',
  'en-QA': '/qa/en/',
  'fr-RE': '/re/fr/',
  'en-SA': '/sa/en/',
  'fr-SN': '/sn/fr/',
  'en-ZA': '/za/en/',
  'en-TZ': '/tz/en/',
  'fr-TZ': '/tz/fr/',
  'fr-TN': '/tn/fr/',
  'en-UG': '/ug/en/',
  'en-AE': '/ae/en/',
  'en-GA': '/ga/en/',
  'fr-GA': '/ga/fr/',
  'en-GH': '/gh/en/',
  'fr-GH': '/gh/fr/',
  'en-MR': '/mr/en/',
  'fr-MR': '/mr/fr/',
  'en-YT': '/yt/en/',
  'fr-YT': '/yt/fr/',
  'en-ZW': '/zw/en/',
  'fr-ZW': '/zw/fr/',
  'en-LS': '/ls/en/',
  'fr-LS': '/ls/fr/',
  'en-AI': '/ai/en/',
  'en-AG': '/ag/en/',
  'es-AN': '/an/es/',
  'es-AR': '/ar/es/',
  'en-AW': '/aw/en/',
  'en-BS': '/bs/en/',
  'en-BB': '/bb/en/',
  'en-BZ': '/bz/en/',
  'en-BM': '/bm/en/',
  'es-BO': '/bo/es/',
  'pt-BR': '/br/pt/',
  'en-KY': '/ky/en/',
  'es-CL': '/cl/es/',
  'es-CO': '/co/es/',
  'es-CR': '/cr/es/',
  'en-DM': '/dm/en/',
  'es-EC': '/ec/es/',
  'es-SV': '/sv/es/',
  'es-GD': '/gd/es/',
  'fr-GP': '/gp/fr/',
  'es-GT': '/gt/es/',
  'en-GY': '/gy/en/',
  'fr-HT': '/ht/fr/',
  'es-HN': '/hn/es/',
  'en-JM': '/jm/en/',
  'fr-MQ': '/mq/fr/',
  'es-MX': '/mx/es/',
  'en-MS': '/ms/en/',
  'es-NI': '/ni/es/',
  'es-PA': '/pa/es/',
  'es-PY': '/py/es/',
  'es-PE': '/pe/es/',
  'es-PR': '/pr/es/',
  'es-DO': '/do/es/',
  'en-LC': '/lc/en/',
  'en-KN': '/kn/en/',
  'en-VC': '/vc/en/',
  'en-SR': '/sr/en/',
  'en-TT': '/tt/en/',
  'en-TC': '/tc/en/',
  'es-UY': '/uy/es/',
  'es-VE': '/ve/es/',
  'en-VI': '/vi/en/',
  'en-VG': '/vg/en/',
  'en-CA': '/ca/en/',
  'fr-GF': '/gf/fr/',
  'fr-PF': '/pf/fr/',
  'en-KZ': '/kz/en/',
  'en-AU': '/au/en/',
  'en-BD': '/bd/en/',
  'en-BN': '/bn/en/',
  'en-KH': '/kh/en/',
  'zh-CN': '/cn/zh/',
  'en-GU': '/gu/en/',
  'zh-HK': '/hk/zh/',
  'in-ID': '/id/in/',
  'ja-JP': '/jp/ja/',
  'ko-KR': '/kr/ko/',
  'en-KG': '/kg/en/',
  'en-LA': '/la/en/',
  'zh-MO': '/mo/zh/',
  'en-MY': '/my/en/',
  'en-MV': '/mv/en/',
  'en-MN': '/mn/en/',
  'en-MM': '/mm/en/',
  'en-NP': '/np/en/',
  'fr-NC': '/nc/fr/',
  'en-NZ': '/nz/en/',
  'en-PC': '/pc/en/',
  'en-PK': '/pk/en/',
  'en-PH': '/ph/en/',
  'en-SG': '/sg/en/',
  'en-LK': '/lk/en/',
  'zh-TW': '/tw/zh/',
  'th-TH': '/th/th/',
  'en-VN': '/vn/en/',
  'vi-VN': '/vn/vi/',
  'en-IQ': '/iq/en/',
  'en-TJ': '/tj/en/',
  'en-TM': '/tm/en/',
  'en-UZ': '/uz/en/',
  'fr-WF': '/wf/fr/',
  'es-AD': '/ad/es/',
  'en-AL': '/al/en/',
  'de-AT': '/at/de/',
  'en-AZ': '/az/en/',
  'en-BY': '/by/en/',
  'fr-BE': '/be/fr/',
  'bg-BG': '/bg/bg/',
  'cs-CZ': '/cz/cs/',
  'hr-HR': '/hr/hr/',
  'en-CY': '/cy/en/',
  'da-DK': '/dk/da/',
  'de-DE': '/de/de/',
  'et-EE': '/ee/et/',
  'fi-FI': '/fi/fi/',
  'fr-FR': '/fr/fr/',
  'en-GE': '/ge/en/',
  'en-GI': '/gi/en/',
  'el-GR': '/gr/el/',
  'en-GL': '/gl/en/',
  'hu-HU': '/hu/hu/',
  'en-IE': '/ie/en/',
  'it-IT': '/it/it/',
  'lv-LV': '/lv/lv/',
  'de-LI': '/li/de/',
  'lt-LT': '/lt/lt/',
  'fr-LU': '/lu/fr/',
  'en-MK': '/mk/en/',
  'en-MT': '/mt/en/',
  'en-MD': '/md/en/',
  'en-ME': '/me/en/',
  'fr-MC': '/mc/fr/',
  'nl-NL': '/nl/nl/',
  'no-NO': '/no/no/',
  'pl-PL': '/pl/pl/',
  'pt-PT': '/pt/pt/',
  'ro-RO': '/ro/ro/',
  'ru-RU': '/ru/ru/',
  'it-SM': '/sm/it/',
  'sk-SK': '/sk/sk/',
  'en-SI': '/si/en/',
  'es-ES': '/es/es/',
  'sv-SE': '/se/sv/',
  'de-CH': '/ch/de/',
  'tr-TR': '/tr/tr/',
  'en-RS': '/rs/en/',
  'en-BA': '/ba/en/',
  'en-IS': '/is/en/',
  'en-UA': '/ua/en/',
  'en-JE': '/je/en/',
  'en-GG': '/gg/en/',
  'da-FO': '/fo/da/',
  'es-BQ': '/bq/es/',
  'es-CW': '/cw/es/',
  'es-SX': '/sx/es/',
  'pt-AO': '/ao/pt/',
  'en-ET': '/et/en/',
  'en-CG': '/cg/en/',
  'uk-UA': '/ua/uk/',
  'en-MF': '/mf/en/',
  'es-MF': '/mf/es/',
  'fr-MF': '/mf/fr/',
  'en-DZ': '/dz/en/',
  'en-BJ': '/bj/en/',
  'fr-BJ': '/bj/fr/',
  'en-CM': '/cm/en/',
  'en-CF': '/cf/en/',
  'en-CI': '/ci/en/',
  'en-GN': '/gn/en/',
  'en-GQ': '/gq/en/',
  'en-LB': '/lb/en/',
  'en-MG': '/mg/en/',
  'en-ML': '/ml/en/',
  'en-MU': '/mu/en/',
  'en-MA': '/ma/en/',
  'en-MZ': '/mz/en/',
  'en-NE': '/ne/en/',
  'en-RE': '/re/en/',
  'en-SN': '/sn/en/',
  'en-TN': '/tn/en/',
  'es-AI': '/ai/es/',
  'es-AG': '/ag/es/',
  'en-AN': '/an/en/',
  'en-AR': '/ar/en/',
  'es-AW': '/aw/es/',
  'es-BS': '/bs/es/',
  'es-BB': '/bb/es/',
  'es-BZ': '/bz/es/',
  'es-BM': '/bm/es/',
  'en-BO': '/bo/en/',
  'en-BR': '/br/en/',
  'es-KY': '/ky/es/',
  'en-CL': '/cl/en/',
  'en-CO': '/co/en/',
  'en-CR': '/cr/en/',
  'es-DM': '/dm/es/',
  'en-EC': '/ec/en/',
  'en-SV': '/sv/en/',
  'en-GD': '/gd/en/',
  'en-GP': '/gp/en/',
  'en-GT': '/gt/en/',
  'es-GY': '/gy/es/',
  'en-HT': '/ht/en/',
  'en-HN': '/hn/en/',
  'es-JM': '/jm/es/',
  'en-MQ': '/mq/en/',
  'en-MX': '/mx/en/',
  'es-MS': '/ms/es/',
  'en-NI': '/ni/en/',
  'en-PA': '/pa/en/',
  'en-PY': '/py/en/',
  'en-PE': '/pe/en/',
  'en-PR': '/pr/en/',
  'en-DO': '/do/en/',
  'es-LC': '/lc/es/',
  'es-KN': '/kn/es/',
  'es-VC': '/vc/es/',
  'es-SR': '/sr/es/',
  'es-TT': '/tt/es/',
  'es-TC': '/tc/es/',
  'en-UY': '/uy/en/',
  'en-VE': '/ve/en/',
  'es-VI': '/vi/es/',
  'es-VG': '/vg/es/',
  'fr-CA': '/ca/fr/',
  'en-GF': '/gf/en/',
  'en-PF': '/pf/en/',
  'en-HK': '/hk/en/',
  'en-MO': '/mo/en/',
  'en-NC': '/nc/en/',
  'en-WF': '/wf/en/',
  'en-AD': '/ad/en/',
  'en-AT': '/at/en/',
  'nl-BE': '/be/nl/',
  'en-BG': '/bg/en/',
  'en-CZ': '/cz/en/',
  'en-HR': '/hr/en/',
  'en-DK': '/dk/en/',
  'en-DE': '/de/en/',
  'en-EE': '/ee/en/',
  'en-FI': '/fi/en/',
  'en-FR': '/fr/en/',
  'es-GI': '/gi/es/',
  'en-GR': '/gr/en/',
  'en-HU': '/hu/en/',
  'en-IT': '/it/en/',
  'en-LV': '/lv/en/',
  'en-LI': '/li/en/',
  'en-LT': '/lt/en/',
  'en-LU': '/lu/en/',
  'en-MC': '/mc/en/',
  'en-NL': '/nl/en/',
  'en-NO': '/no/en/',
  'en-PL': '/pl/en/',
  'en-PT': '/pt/en/',
  'en-RO': '/ro/en/',
  'en-RU': '/ru/en/',
  'en-SM': '/sm/en/',
  'en-SK': '/sk/en/',
  'en-ES': '/es/en/',
  'en-SE': '/se/en/',
  'fr-CH': '/ch/fr/',
  'en-TR': '/tr/en/',
  'en-FO': '/fo/en/',
  'en-BQ': '/bq/en/',
  'en-CW': '/cw/en/',
  'en-SX': '/sx/en/',
  'en-AO': '/ao/en/',
  'en-TH': '/th/en/',
  'en-ID': '/id/en/',
  'fr-AN': '/an/fr/',
  'fr-AW': '/aw/fr/',
  'es-GP': '/gp/es/',
  'es-HT': '/ht/es/',
  'es-MQ': '/mq/es/',
  'fr-VI': '/vi/fr/',
  'fr-VG': '/vg/fr/',
  'en-BE': '/be/en/',
  'en-CH': '/ch/en/',
  'es-GF': '/gf/es/',
  'fr-BQ': '/bq/fr/',
  'fr-CW': '/cw/fr/',
  'fr-SX': '/sx/fr/',
  'en-CK': '/ck/en/',
  'en-FJ': '/fj/en/',
  'en-PG': '/pg/en/',
  'en-TO': '/to/en/',
  'en-VU': '/vu/en/',
  'ar-AE': '/ae/ar/',
  'ar-BH': '/bh/ar/',
  'ar-EG': '/eg/ar/',
  'ar-IQ': '/iq/ar/',
  'ar-JO': '/jo/ar/',
  'ar-KW': '/kw/ar/',
  'ar-LB': '/lb/ar/',
  'ar-OM': '/om/ar/',
  'ar-QA': '/qa/ar/',
  'ar-SA': '/sa/ar/',
};
