import React from 'react';

export interface SelectorLabelProps {
  htmlFor: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  [key: string]: any;
}

const SelectorLabel = ({ htmlFor, title, children, className, ...props }: SelectorLabelProps): JSX.Element => {
  const classes = ['form-selector-label', className].filter(Boolean).join(' ');
  return (
    <label className={classes} htmlFor={htmlFor} {...props}>
      {title && <span className="form-selector-title">{title}</span>}
      {children}
    </label>
  );
};

export default SelectorLabel;
