import axios from 'axios';
import https from 'https';

const axiosInstance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: process.env.HTTPS_AGENT_UNAUTHORIZED === 'true',
  }),
  baseURL: '/api/v1/grlui/',
  timeout: 30000,
});

export default axiosInstance;
