import React, { memo } from 'react';
import './banner.scss';

const Banner = ({ children, className = '' }: { children: React.ReactNode; className?: string }): JSX.Element => {
  return (
    <div className="store-banner">
      <div className={`banner ${className}`}>{children}</div>
    </div>
  );
};

export default memo(Banner);
