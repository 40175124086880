import React from 'react';
import { BrowserRouter as GRLRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { routes } from './route';
import StoreProvider from './store/store';

const App = (): JSX.Element => {
  return (
    <StoreProvider>
      <GRLRouter>
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component title={route.name} />
            </Route>
          ))}
        </Switch>
      </GRLRouter>
    </StoreProvider>
  );
};

export default App;
