import React, { forwardRef } from 'react';
import './form-message.scss';

export interface FormErrorProps {
  id: string;
  description?: string;
  type: 'error' | 'info' | 'warning' | 'success';
}

const FormMessage = ({ id, description = '' }: FormErrorProps): JSX.Element => (
  <div id={id} className="form-message-wrapper">
    <span className="visuallyhidden">Error:</span>
    {description && <span className="form-message">{description}</span>}
  </div>
);

export default forwardRef(FormMessage);
