import React, { memo } from 'react';
import './loader.scss';

const Loader = ({ ariaLabel = '' }: { ariaLabel?: string }): JSX.Element => {
  return (
    <div aria-live="polite" role="status" className="loading-shell">
      <div className="spinner" />
      <span className="visuallyhidden">{ariaLabel}</span>
    </div>
  );
};

export default memo(Loader);
