/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './autocomplete.scss';
interface AutoCompleteItemProps {
  onSelectItem: (item: any) => void;
  item: any;
  isActive: boolean;
  [key: string]: any;
}

const AutoCompleteItem = ({ isActive = false, onSelectItem, item, ...props }: AutoCompleteItemProps) => {
  return (
    <li
      onClick={() => onSelectItem(item)}
      aria-selected={isActive ? true : false}
      role="option"
      tabIndex={-1}
      {...props}
      className={`suggest-item${isActive ? ' active' : ''}`}>
      <p className="line-1 typography-body">{item.lines[0]}</p>
      <p className="line-2 typography-body-reduced">{item.lines[1]}</p>
    </li>
  );
};

export default AutoCompleteItem;
