import set from 'lodash-es/set';
import get from 'lodash-es/get';
import unset from 'lodash-es/unset';
import clone from 'lodash-es/clone';
import { useCallback, useLayoutEffect, useRef } from 'react';

export const useEventCallback = (fn: any): any => {
  const ref = useRef(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: any) => ref.current.apply(undefined, args), []);
};

export const getIn = (obj: any, path: string): any => get(obj, path);

export const setIn = (obj: any, path: string, value: any): any => {
  const response = clone(obj);
  set(response, path, value);
  return response;
};

export const unsetIn = (obj: any, path: string): any => {
  const response = clone(obj);
  unset(response, path);
  return response;
};
