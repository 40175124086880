import React, { forwardRef, ReactNode } from 'react';

export interface DropdownOptionProps {
  children: ReactNode;
  value: string;
  [key: string]: any;
}

type OptionRef = React.Ref<HTMLOptionElement>;

const DropdownOption = ({ children, value, ...rest }: DropdownOptionProps, forwardRef: OptionRef) => (
  <option ref={forwardRef} value={value} {...rest}>
    {children}
  </option>
);
export default forwardRef(DropdownOption);
