import React from 'react';
import { Button } from '../ac-forms';
import { useTranslation } from 'react-i18next';
import './no-match.scss';

const NoMatch = ({ onReset }: { onReset: () => void }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="no-match">
      <p>{t('page.results.filters.noResultMsg')}</p>
      <strong>{t('page.results.filters.noResultsMsgOr')}</strong>
      <Button block onClick={onReset}>
        {t('page.results.filters.resetFiltersLink')}
      </Button>
    </div>
  );
};

export default NoMatch;
