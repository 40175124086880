import React, { forwardRef } from 'react';
import FormMessage from '../form-message/form-message';
import './input.scss';

export interface InputProps {
  label: string;
  id?: string;
  type: 'text';
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  resetIcon?: boolean;
  btnIcon?: string;
  message?: {
    type: 'info' | 'error' | 'warning' | 'success';
    description: string;
  };
  required?: boolean;
  readonly?: boolean;
  btnText?: string;
  btnTextDisabled?: boolean;
  btnAriaLabel?: string;
  onChange?: (value: any) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onReset?: () => void;
  onTextBtnClick?: (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void;
  [key: string]: any;
}
const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    id,
    type = 'text',
    disabled,
    placeholder = ' ',
    value = '',
    readonly = false,
    required = false,
    leftIcon,
    rightIcon,
    btnIcon = '',
    resetIcon = false,
    message,
    btnText = '',
    btnTextDisabled = false,
    btnAriaLabel = '',
    onBlur,
    onChange,
    onFocus,
    onReset,
    onTextBtnClick,
    ...props
  }: InputProps,
  forwardRef,
): JSX.Element => {
  const classes = [
    'form-textbox',
    resetIcon || rightIcon ? 'form-textbox-with-righticon' : '',
    leftIcon ? 'form-textbox-with-lefticon' : '',
    message?.type === 'error' ? 'is-error' : '',
    btnText || btnIcon ? 'form-textbox-with-button' : '',
  ]
    .filter(Boolean)
    .join(' ');

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
  };

  const handleTextBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.detail > 0) onTextBtnClick && onTextBtnClick(event);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === ' ' || event.key === 'Enter') onTextBtnClick && onTextBtnClick(event);
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onReset && onReset();
  };

  return (
    <div className={classes}>
      <input
        id={id}
        data-testid="ac-form-input"
        type={type}
        value={value}
        className={`form-textbox-input${value ? ' form-textbox-entered' : ''}`}
        aria-labelledby={`com.apple.sse.grl:grlui-core:jar:1.2.9-SNAPSHOT_label`}
        aria-invalid={message?.type === 'error'}
        aria-describedby={`com.apple.sse.grl:grlui-core:jar:1.2.9-SNAPSHOT_error`}
        required={required}
        readOnly={readonly}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        ref={forwardRef}
        {...props}
      />
      <span className="form-textbox-label" id={`com.apple.sse.grl:grlui-core:jar:1.2.9-SNAPSHOT_label`} aria-hidden="true">
        {label}
      </span>
      {leftIcon && (
        <div className="form-textbox-lefticon form-icons-focusable">
          <span className={`form-icons ${leftIcon}`} />
        </div>
      )}
      {rightIcon && (
        <div className="form-textbox-righticon">
          <span className={`form-icons ${rightIcon}`} id="textbox2_righticon" />
        </div>
      )}
      {btnIcon && (
        <button
          className="form-textbox-button"
          onClick={handleTextBtnClick}
          onKeyUp={handleKeyUp}
          disabled={btnTextDisabled}
          aria-label={btnAriaLabel}>
          <span className={`form-icons ${btnIcon}`} id="textbox2_righticon" />
        </button>
      )}
      {btnText && (
        <button
          className="form-textbox-button"
          disabled={btnTextDisabled}
          onClick={handleTextBtnClick}
          onKeyUp={handleKeyUp}
          aria-label={btnAriaLabel}>
          {btnText}
        </button>
      )}
      {resetIcon && (
        <div className="form-textbox-righticon form-icons-focusable">
          <button onClick={handleReset} className="form-icons form-icons-reset">
            <span className="visuallyhidden">Search Reset</span>
          </button>
        </div>
      )}
      {message && message.description ? <FormMessage id={`com.apple.sse.grl:grlui-core:jar:1.2.9-SNAPSHOT_error`} description={message.description} type={message.type} /> : null}
    </div>
  );
};

export default forwardRef(Input);
