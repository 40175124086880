import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BADGE_MAP } from '../../constants';
import { Product } from '../../models/api-interface';
import './store-info.scss';

export interface StoreProps {
  orderNumber: number;
  location?: {
    latitude: number;
    longitude: number;
  };
  country: string;
  channel: string;
  casRedirect: boolean;
  apptSchedulerIndVal: boolean;
  title: string;
  badge?: string[];
  addressLine1?: string;
  addressLine2?: string;
  products?: Array<Product>;
  distance?: string;
  active?: boolean;
  focus?: boolean;
  onHover?: (orderNumber: number, hover: boolean) => void;
  onClick: (index: number) => void;
  [key: string]: any;
}

const StoreInfo = ({
  orderNumber,
  title,
  badge = [],
  active = false,
  focus = false,
  addressLine1,
  addressLine2,
  products = [],
  distance,
  country,
  channel,
  casRedirect,
  apptSchedulerIndVal,
  onHover,
  onClick,
  ...props
}: StoreProps): JSX.Element => {
  const { t } = useTranslation();
  const storeRef = useRef<HTMLButtonElement>(null);
  const uniqProducts = products.map((prod) => prod.productRibbonName).filter((product, index, array) => array.indexOf(product) == index);

  useEffect(() => {
    if (focus) {
      storeRef.current?.focus();
    }
  }, [focus]);

  return (
    <li id={`store-${orderNumber}`} {...props}>
      <button
        ref={storeRef}
        aria-label={`${orderNumber}. ${title} ${addressLine1 || ''} ${addressLine2 || ''} located ${distance} away from selected location.`}
        className={`store-info ${active ? 'active' : ''}`}
        onMouseEnter={() => onHover && onHover(orderNumber, true)}
        onMouseLeave={() => onHover && onHover(orderNumber, false)}
        onClick={() => onClick(orderNumber)}>
        <div aria-hidden="true" className="store-number">
          <span className="store-count">{orderNumber}</span>
        </div>
        <div className="store-content">
          <div className={`store-name${badge ? ' with-badge' : ''}`}>
            <span className="title">{title}</span>
            <span className="badges">
              {badge.map((badgeItem) => (
                <span key={badgeItem} className="badge">
                  {BADGE_MAP[badgeItem]}
                </span>
              ))}
            </span>
          </div>
          <div aria-hidden="true" className="store-address">
            <p className="address-line-1">{addressLine1}</p>
            <p className="address-line-2">{addressLine2}</p>
          </div>
          {country &&
          (country.toLowerCase() === 'in' || country.toLowerCase() === 'cn') &&
          apptSchedulerIndVal &&
          !casRedirect &&
          channel === 'service' &&
          products.length > 0 ? (
            <div className="repair-link">{t('page.results.appointmentSchedulerLabel')}</div>
          ) : (
            <div className="store-nav">
              <div aria-label="Products" className="product-list">
                {uniqProducts.map((prod) => (
                  <span key={prod} className="product">
                    {prod}
                  </span>
                ))}
              </div>
              <div className="distance">{distance}</div>
            </div>
          )}
        </div>
      </button>
    </li>
  );
};

export default StoreInfo;
