export class Directions {
  private directions: mapkit.Directions;
  private reqId: number | undefined;
  constructor(options?: any) {
    this.directions = new mapkit.Directions(options);
  }

  getRoute(request: mapkit.DirectionsRequest): Promise<Error | mapkit.DirectionsResponse> {
    if (this.reqId) {
      this.directions.cancel(this.reqId);
    }
    return new Promise((resolve, reject) => {
      this.reqId = this.directions.route(request, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getEta(request: mapkit.EtaRequestOptions): Promise<mapkit.EtaResponse> {
    if (this.reqId) {
      this.directions.cancel(this.reqId);
    }
    return new Promise((resolve, reject) => {
      this.reqId = this.directions.eta(request, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }
}
