import React, { memo } from 'react';
import './local-nav.scss';

export interface LocalNavProps {
  title: string;
  stickNav?: boolean;
  longProductTitle?: boolean;
  titleLink?: string;
  initialState?: 'default' | 'scrim';
  border?: boolean;
  collapseAt?: 'small' | 'medium' | 'large';
  availability?: string;
  links?: Array<{
    id: string | number;
    link: string;
    name: string;
    class: string;
  }>;
  actions?: Array<{
    id: string | number;
    name: string;
    link: string;
    type: 'neutral' | 'secondary' | 'secondary-alpha' | 'primary';
    buttonClass: string;
    customClass: string;
  }>;
}

const LocalNav = ({
  title,
  initialState = 'default',
  stickNav = false,
  border = true,
  longProductTitle = false,
  collapseAt = 'small',
  availability = '',
  links = [],
  actions = [],
}: LocalNavProps): JSX.Element => {
  const nav_class = [
    'localnav',
    availability ? 'localnav-stacked' : '',
    !border ? 'localnav-noborder' : '',
    initialState === 'scrim' ? 'localnav-scrim' : '',
    `${collapseAt}-localnav-collapsible`,
  ]
    .filter(Boolean)
    .join(' ');
  const nav_title = [
    'localnav-title',
    availability || longProductTitle ? 'localnav-title-compact' : '',
    stickNav ? 'localnav-sticking' : '',
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <React.Fragment>
      <input type="checkbox" id="localnav-menustate" className="localnav-menustate" />
      <nav id="localnav" aria-label="Local" className={nav_class} data-sticky role="navigation">
        <div className="localnav-wrapper">
          <div className="localnav-background" />
          <div className="localnav-content">
            <div className={nav_title}>
              <div className="typography-eyebrow">
                {title}
                {availability ? <span className="localnav-title-comingsoon">{availability}</span> : null}
              </div>
            </div>
            <div className="localnav-menu">
              <a
                href="#localnav-menustate"
                role="button"
                className="localnav-menucta-anchor localnav-menucta-anchor-open"
                id="localnav-menustate-open">
                <span className="localnav-menucta-anchor-label">Local Nav Open Menu</span>
              </a>
              <a href="#" role="button" className="localnav-menucta-anchor localnav-menucta-anchor-close" id="localnav-menustate-close">
                <span className="localnav-menucta-anchor-label">Local Nav Close Menu</span>
              </a>
              {links.length > 0 ? (
                <div className="localnav-menu-tray">
                  <ul className="localnav-menu-items">
                    {links.map((link) => (
                      <li key={link.id} className={`localnav-menu-item ${link.class ? link.class : ''}`}>
                        <a href={link.link} className="localnav-menu-link">
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {actions.length > 0 || links.length > 0 ? (
                <div className="localnav-actions">
                  <div className="localnav-action localnav-action-menucta" aria-hidden="true">
                    <label htmlFor="localnav-menustate" className="localnav-menucta">
                      <span className="localnav-menucta-chevron" />
                    </label>
                  </div>
                  {actions.map((action) => (
                    <div key={action.id} className="localnav-action localnav-action-button">
                      <a
                        className={`localnav-button button ${action.type ? `button-${action.type}` : ''} button-reduced`}
                        href={action.link}>
                        {action.name}
                        <span className="localnav-action-product">{title}</span>
                      </a>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </nav>
      <label id="localnav-curtain" htmlFor="localnav-menustate"></label>
    </React.Fragment>
  );
};

export default memo(LocalNav);
