import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Radar } from '../../assets/radar.svg';
import { ReactComponent as Share } from '../../assets/share.svg';
import { ReactComponent as StoreImg } from '../../assets/store.svg';
import Icon from '../../components/icon';
import Map from '../../components/maps';
import { BADGE_MAP, DIRECTIONS, EVENT_MAP, GEOCODESTATUS } from '../../constants';
import { Product } from '../../models/api-interface';
import { ModifiedStore } from '../../models/store-models';
import { trackEvent } from '../../services/utility';
import { SearchPayload } from '../../store/model';
import Loader from '../loader';
import { Marker } from '../maps';
import { createCoordinate } from '../maps/map-utility';
import { Directions } from '../maps/services/directions';
import SvgIcon from '../svg-icon';
import './StoreDetail.scss';

interface StoreDetailProps {
  store: ModifiedStore;
  source: SearchPayload;
  badges?: string[];
  country: string;
  channel: string;
  locale: string;
  getDirection: string;
  directions: Directions;
  disableMap: boolean;
  casRedirect: boolean;
  selectedProduct: string;
  products: Product[];
  casSupportUrl: string;
  totalStores: number;
  address: string;
  mode: string;
}

const StoreDetail = ({
  store,
  source,
  channel,
  selectedProduct,
  locale,
  casRedirect,
  casSupportUrl,
  address,
  totalStores,
  disableMap,
  getDirection,
  directions,
  country,
  mode,
}: StoreDetailProps) => {
  const {
    orderNumber,
    title,
    distance,
    storeBadges = [],
    street1,
    street2,
    city,
    state,
    postalCode,
    phone,
    serviceTypes = [],
    solutionsSpecialties = [],
    storeId,
    latitude,
    longitude,
    geoCodeStatus = '',
    salesProducts = [],
    serviceProducts = [],
    storeWeb,
    apptSchedulerIndVal,
  } = store;
  const products = channel === 'sales' ? salesProducts : serviceProducts;
  const isRetailStore = store.storeTypes?.includes(5) || false;
  const [transportMode, setTransportMode] = useState(mode.toLowerCase());
  const [etaLoader, setEtaLoader] = useState(false);
  const [travelTime, setTravelTime] = useState<{ hours: number; minutes: number }>();
  const { t } = useTranslation();

  const getDirectionsLink = (getDirLink: string, destination: string, lat: number, lng: number) => {
    let dirlink = getDirLink;
    if (lat && lng) {
      dirlink += `saddr=${lat},${lng}`;
    } else {
      dirlink += `saddr=${source.formattedAddress}`;
    }
    if (GEOCODESTATUS.includes(geoCodeStatus)) {
      dirlink += `&daddr=${source.latitude},${source.longitude}&`;
    } else {
      dirlink += `&daddr=${destination}`;
    }
    if (source.lang) {
      dirlink += `&hl=${source.lang}`;
    }
    return dirlink;
  };

  const secondsToHms = (d: number): { hours: number; minutes: number } => {
    const hours = Math.floor(d / 3600);
    const minutes = Math.floor((d % 3600) / 60);
    return { hours, minutes };
  };

  const getReservationRepaired = () => {
    const location = window.location.href;
    const carrierUrl = location.substring(location.indexOf('carrier'), location.length);
    const product = products.find((prod) => prod._id.toString() === selectedProduct);
    const casProd = product ? product.pdmSuperGrpProductId.split(/(\d+)/) : '';
    if (location.indexOf('carrier') > -1) {
      return `${casSupportUrl}${locale}&storeID=${storeId}&lat=${latitude}&long=${longitude}&${carrierUrl}&${
        casProd[0] + '=' + product?.pdmSuperGrpProductId
      }`;
    } else {
      return `${casSupportUrl}${locale}&storeID=${storeId}&lat=${latitude}&long=${longitude}&${
        casProd[0] + '=' + product?.pdmSuperGrpProductId
      }`;
    }
  };

  const getHttp = (carrierUrl: string) => {
    if (carrierUrl && !/^(f|ht)tps?:\/\//i.test(carrierUrl)) {
      carrierUrl = 'http://' + carrierUrl;
    }
    return carrierUrl;
  };

  const sendAnalytics = (event: any) => {
    const product = products.find((prod) => prod._id.toString() === selectedProduct);
    trackEvent(
      EVENT_MAP.SEARCH,
      {
        link_owner: 'ac_link',
        name: 'store map view',
        type: 'simple link',
        link_text: event.target.innerText,
        dest_url: event.target.innerText,
      },
      { list: [product?.displayName] },
      {
        term: address,
        results: `${totalStores}`,
        resultType: 'user',
        store_ID: storeId,
        resultViewType: 'find location results',
        result_index: orderNumber,
      },
    );
  };

  const changeTransportMode = (mode: string) => {
    setTransportMode(mode);
    setEtaLoader(true);
    directions
      .getEta({
        origin: createCoordinate(Number(source.latitude), Number(source.longitude)),
        destinations: [createCoordinate(Number(latitude), Number(longitude))],
        transportType: mode === 'automobile' ? mapkit.Directions.Transport.Automobile : mapkit.Directions.Transport.Walking,
        departureDate: new Date(),
      })
      .then((data) => {
        const { etas } = data;
        if (etas && etas.length > 0) {
          const time = secondsToHms(etas[0].expectedTravelTime);
          setTravelTime(time);
          setEtaLoader(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setEtaLoader(false));
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: title,
          text: [title, street1, street2, state, phone].join(', '),
          url: getDirectionsLink(
            getDirection,
            `${[street1, street2, city, state, postalCode, country].filter(Boolean).join(',')}`,
            latitude,
            longitude,
          ),
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="store-detail medium-show">
      <StoreImg className="store-icon" />
      <div className="store-detail-content">
        <div className="store-header">
          <span className="title">{title}</span>
        </div>
        {storeBadges.length > 0 && (
          <div className="badges">
            {storeBadges.map((badgeItem) => (
              <span key={badgeItem} title={badgeItem} className="badge">
                {BADGE_MAP[badgeItem]}
              </span>
            ))}
          </div>
        )}
        <div className="location">
          <div className="location_area">
            <p className="ellipsis uppercase">{[street1, street2].filter(Boolean).join(', ')}</p>
            {city && <p className="ellipsis uppercase">{[city, state, postalCode].filter(Boolean).join(', ')}</p>}
            {phone && <p className="ellipsis uppercase">{phone}</p>}
          </div>
        </div>
      </div>
      {!(source.countryCode === 'cn' && source.lang === 'zh') && (
        <div className="driving-distance">
          <div className="travel-time">
            {DIRECTIONS.map((direction) => (
              <button
                key={direction}
                aria-label={`Driving distance for ${direction}`}
                onClick={() => changeTransportMode(direction)}
                className={`direction${direction === transportMode ? ' active' : ''}`}>
                <SvgIcon className="transport-icon" product={direction} />
              </button>
            ))}
          </div>
          {etaLoader ? (
            <Loader />
          ) : (
            <div className="address-distance">
              {travelTime?.hours === 0 && (
                <Fragment>
                  <strong>{travelTime.minutes}</strong>&nbsp;mins
                  <br />
                </Fragment>
              )}
              {travelTime && travelTime.hours > 0 && (
                <Fragment>
                  <strong>{travelTime.hours}</strong>&nbsp;hrs&nbsp;<strong>{travelTime.minutes}</strong>&nbsp;mins
                  <br />
                </Fragment>
              )}
              {`(${distance})`}
            </div>
          )}
        </div>
      )}
      <div className="all-links">
        {isRetailStore && (
          <Icon
            as="link"
            className="more"
            href={getHttp(storeWeb)}
            label={channel === 'sales' ? t('infoWindow.viewStoreDetails') : t('infoWindow.linkGeniusAppointments')}
            after
            onClick={sendAnalytics}
            name="chevron-right"
          />
        )}
        {!isRetailStore && (
          <Fragment>
            {!disableMap && (
              <Icon
                as="link"
                className="more"
                href={getDirectionsLink(
                  getDirection,
                  `${[street1, street2, city, state, postalCode, country].filter(Boolean).join(',')}`,
                  latitude,
                  longitude,
                )}
                label={t('infoWindow.getDirections')}
                after
                name="chevron-right"
              />
            )}
            {storeWeb && (
              <Icon
                as="link"
                target="_blank"
                href={getHttp(storeWeb)}
                className="more"
                srLabel={t('infoWindow.websiteContext')}
                label={t('infoWindow.websites')}
                after
                onClick={sendAnalytics}
                name="chevron-right"
              />
            )}
            {country &&
              (country.toLowerCase() === 'in' || country.toLowerCase() === 'cn') &&
              apptSchedulerIndVal &&
              !casRedirect &&
              channel === 'service' &&
              serviceProducts &&
              serviceProducts.length > 0 && (
                <Icon
                  as="link"
                  className="more"
                  href={getReservationRepaired()}
                  label={t('page.results.appointmentSchedulerLabel')}
                  after
                  onClick={sendAnalytics}
                  name="chevron-right"
                />
              )}
          </Fragment>
        )}
      </div>
      {!isRetailStore && (
        <div className="products">
          {products.length > 0 && (
            <div className="sales-products">
              <p className="heading">{t('infoWindow.productSales')}</p>
              <ul className="product-list">{salesProducts.map((product) => product.displayName).join(', ')}</ul>
            </div>
          )}
          {serviceProducts.length > 0 && (
            <div className="service-products">
              <p className="heading">{t('infoWindow.productService')}</p>
              <ul className="product-list">{serviceProducts.map((item) => item.displayName).join(', ')}</ul>
            </div>
          )}
          {serviceTypes.length > 0 && (
            <div className="service-types">
              <p className="heading">{t('infoWindow.serviceType')}</p>
              <p className="service-mode">
                {serviceTypes.map((service) => (
                  <span className="mode" key={service._id}>
                    {t(`page.results.filters.repairTypes.${service.code}`)}
                  </span>
                ))}
              </p>
            </div>
          )}
          {solutionsSpecialties.length > 0 && (
            <div className="service-types">
              <p className="heading">{t('infoWindow.specialities')}</p>
              <p className="service-mode">
                {solutionsSpecialties.map((service) => (
                  <span className="mode" key={service._id}>
                    {t(`page.results.filters.solutionsSpecialties.${service.code}`)}
                  </span>
                ))}
              </p>
            </div>
          )}
        </div>
      )}
      <div className="location-map">
        <div className="location-text">
          <Radar className="radar-icon" />
          Location Map
        </div>
        <Map center={{ latitude, longitude }} adjustMap={1}>
          <Marker
            selected
            coordinate={createCoordinate(latitude, longitude)}
            glyphText=""
            srLabel={`${t('infoWindow.pinAltText')} ${store.orderNumber}. ${store.title} ${store.street1 + store.street2}`}
            color="#0066CC"
            glyphColor="#FFFFFF"
            store={store}
            disableCallout={true}
          />
        </Map>
      </div>
      <div className="share">
        <button aria-label="Share location" className="share-btn" onClick={handleShare}>
          Share
          <Share className="share-icon" />
        </button>
      </div>
    </div>
  );
};

export default StoreDetail;
