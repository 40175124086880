import React, { forwardRef } from 'react';
import './button.scss';
export interface ButtonProps {
  size?: 'super' | 'elevated' | 'reduced';
  block?: boolean;
  color?: 'neutral' | 'secondary' | 'secondary-alpha';
  customClass?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: string;
  children?: React.ReactNode;

  [propName: string]: unknown;
}

const Button = (
  { size, block, color, customClass, disabled, onClick, children, ...props }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const classes = [
    'button',
    size ? 'button-' + size : '',
    block ? 'button-block' : '',
    color ? 'button-' + color : '',
    customClass ? customClass : '',
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <button ref={ref} {...props} className={classes} disabled={disabled} data-testid="ac-button" onClick={onClick}>
      {children}
    </button>
  );
};
export default forwardRef(Button);
