import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EVENT_MAP } from '../../constants';
import { LOCALE_MAPPING } from '../../constants/locale-mapping';
import { trackEvent } from '../../services/utility';
import { Actions } from '../../store/model';
import { StoreContext } from '../../store/store';
import { Sanitized } from '@idms/react-sanitized';
import Icon from '../icon';
import './widget-info.scss';

const WidgetInfo = ({ channel, locale }: { channel: string; locale: string }): JSX.Element => {
  const history = useHistory();
  const { dispatch } = useContext(StoreContext);
  const { t } = useTranslation();

  const goBack = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch({ type: Actions.RESET_RESULTS });
    trackEvent(EVENT_MAP.CLICK, {
      link_owner: 'ac_link',
      name: channel,
      type: 'simple link',
      link_text: event.target.innerText,
      dest_url: `${window.location.origin}/${locale ? LOCALE_MAPPING[locale] : '/'}`,
    });
    if (locale) {
      const path = LOCALE_MAPPING[locale];
      history.push(path);
    } else {
      history.push(`/`);
    }
  };

  return (
    <div className="widget text-center">
      <Sanitized tagName="h1" className="widget__heading" html={t(`${channel}.results.title`)} />
      <Sanitized tagName="p" className="widget__description" html={t(`${channel}.results.description`)} />
      <Icon
        as="link"
        label={t(`${channel}.results.homeLink`)}
        name={locale.startsWith('ar-') ? 'chevronright' : 'chevronleft'}
        before
        onClick={goBack}
      />
    </div>
  );
};

export default memo(WidgetInfo);
