import ReactDOM from 'react-dom';
import { Coordinate, CoordinateRegion, CoordinateSpan, MapRect, Padding, PaddingType, RegionType } from './map-models';

export const createPadding = (padding: PaddingType): Padding => {
  return new mapkit.Padding(
    typeof padding === 'number'
      ? {
          top: padding,
          right: padding,
          bottom: padding,
          left: padding,
        }
      : padding,
  );
};

export const createCoordinate = (latitude: number, longitude: number): Coordinate => {
  return new mapkit.Coordinate(latitude, longitude);
};

export const createCoordinateSpan = (latitudeDelta: number, longitudeDelta: number): CoordinateSpan => {
  return new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta);
};

export const createCoordinateRegionFromValues = (region: RegionType): CoordinateRegion => {
  return createCoordinateRegion(
    createCoordinate(region.latitude, region.longitude),
    createCoordinateSpan(region.latitudeSpan, region.longitudeSpan),
  );
};

export const createCoordinateRegion = (center: Coordinate, span: CoordinateSpan): CoordinateRegion => {
  return new mapkit.CoordinateRegion(center, span);
};

export const createMapRect = (x: number, y: number, width: number, height: number): MapRect => {
  return new mapkit.MapRect(x, y, width, height);
};

export function mapEventsListeners(props: { [x: string]: any }): Array<{ [key: string]: any }> {
  return Object.keys(props).reduce((chain: Array<{ [key: string]: any }>, key: string) => {
    const matches = key.match(/^on([A-Z].*)$/);
    if (matches) {
      const [prop, camelType] = matches;
      const type = camelType.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      return [...chain, { prop, type, listener: props[key] }];
    }
    return chain;
  }, []);
}

export const removeEmpty = (obj: any): any =>
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeEmpty(val);
    else if (val == null) delete obj[key];
  });

export function createElementFromJSX(retail: boolean, orderNumber: number, max_size: boolean, med_size: boolean, component?: any): Element {
  const div: HTMLElement = document.createElement('div');
  div.className = `address-card${retail ? ' retail-store' : ''}${!retail && med_size ? ' medium-card' : ''}${
    !retail && max_size ? ' large-card' : ''
  }`;
  div.setAttribute('id', `store-list-${orderNumber}`);
  div.setAttribute('role', 'dialog');
  div.setAttribute('aria-labelledby', 'store-name-id');
  component && ReactDOM.render(component, div);
  return div;
}
