import { useCallback, useMemo, useReducer, useRef } from 'react';
import { formReducer, FormState } from './reducer';
import { Values } from './models';
import { useEventCallback, getIn, setIn } from './utility';
import { Actions } from './enum';
import isEqual from 'lodash-es/isEqual';
import { FormElement } from '../../../models';

export interface FormReturnType extends FormState {
  setValues: (values: any) => void;
  setFieldValue: (field: string, payload?: any) => void;
  setFieldError: (field: string, error: any) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setStatus: (status: string) => void;
  setErrors: (errors: any) => void;
  setTouched: (touched: boolean) => void;
  setFieldTouched: (field: string, touched: boolean) => void;
  handleChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  handleBlur: (event: any) => void;
  resetField: (field: string) => void;
  resetForm: () => void;
  fieldRegistryRef: React.Ref<{ [name: string]: any }>;
  registerFieldRef: (el: any) => void;
  isDirty: boolean;
  isValid: boolean;
  values: Values;
  errors: Values;
}

export interface UseFormProps {
  initialValues?: Values;
  initialErrors?: Values;
  initialStatus?: Values;
  initialTouched?: Values;
  initialSchemaErrors?: Values;
  formConfig: FormElement[];
  validate?: (values: Values) => Values;
  onSubmit?: (values: Values) => void;
}

const useForm = ({
  initialStatus = {},
  initialValues = {},
  initialErrors = {},
  initialTouched = {},
  initialSchemaErrors = {},
  formConfig = [],
  onSubmit,
}: UseFormProps): FormReturnType => {
  const [store, dispatch] = useReducer(formReducer, {
    status: initialStatus,
    values: initialValues,
    errors: initialErrors,
    touched: initialTouched,
    schema_errors: initialSchemaErrors,
    isSubmitting: false,
  });
  const fieldRegistryRef = useRef<{ [name: string]: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }>({});

  const registerFieldRef = (el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement): void => {
    if (el && el.name && fieldRegistryRef.current) {
      fieldRegistryRef.current[el.name] = el;
    }
  };

  const setValues = useCallback((values) => {
    dispatch({ type: Actions.SET_VALUES, payload: values });
  }, []);

  const setErrors = useCallback((errors) => {
    dispatch({ type: Actions.SET_ERRORS, payload: errors });
  }, []);

  const setFieldValue = useEventCallback((field: string, value: any) => {
    dispatch({
      type: Actions.SET_FIELD_VALUE,
      payload: { field, value },
    });
    if (store.errors[field]) {
      dispatch({
        type: Actions.SET_FIELD_VALID,
        payload: { field },
      });
    }
    setFieldTouched(field, value);
    runSchemaValidation(setIn(store.values, field, value));
  });

  const setFieldError = useCallback((field: string, error: any) => {
    dispatch({
      type: Actions.SET_FIELD_ERROR,
      payload: { field, value: error },
    });
  }, []);

  const resetField = useCallback(
    (field) => {
      dispatch({
        type: Actions.RESET_FIELD,
        payload: {
          field,
          value: getIn(initialValues, field),
        },
      });
    },
    [initialValues],
  );

  const resetForm = useCallback(() => {
    dispatch({
      type: Actions.RESET,
      payload: {},
    });
    runSchemaValidation({});
  }, [initialValues]);

  const setIsSubmitting = useCallback((isSubmitting) => {
    dispatch({ type: Actions.SET_ISSUBMITTING, payload: isSubmitting });
  }, []);

  const setStatus = useCallback((status: string) => {
    dispatch({ type: Actions.SET_STATUS, payload: status });
  }, []);

  const handleChange = useCallback(
    (event) => {
      event.persist();
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      setFieldValue(target.name, value);
    },
    [setFieldValue],
  );

  const setTouched = useCallback((touched: boolean) => {
    dispatch({ type: Actions.SET_TOUCHED, payload: touched });
  }, []);

  const setFieldTouched = useEventCallback((field: string, touched: boolean) => {
    dispatch({
      type: Actions.SET_FIELD_TOUCHED,
      payload: { field, value: touched },
    });
    runSchemaValidation(store.values);
  });

  const handleBlur = useCallback(
    (event) => {
      const target = event.target;
      setFieldTouched(target.name, true);
    },
    [setFieldTouched],
  );

  const runSchemaValidation = useCallback(
    (values) => {
      const errors: { [key: string]: any } = {};
      formConfig.forEach((form) => {
        if (form.showOnly) {
          if (
            form.name === 'carrier' &&
            form.options &&
            form.options.length > 0 &&
            !values[form.name] &&
            values[form.showOnly] &&
            (values[form.showOnly].toLowerCase() === 'iphone' || values[form.showOnly].toLowerCase() === 'ipad')
          ) {
            errors[form.name] = true;
          } else if (form.name === 'products' && !values[form.name] && values[form.showOnly]) {
            errors[form.name] = true;
          }
        } else {
          if (form.required && values && !values[form.name]) {
            errors[form.name] = true;
          }
        }
      });
      dispatch({ type: Actions.SCHEMA_ERRORS, payload: errors });
    },
    [formConfig],
  );

  const handleSubmit = useEventCallback((event: any) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    submitForm();
  });

  const submitForm = useEventCallback(() => {
    dispatch({ type: Actions.SUBMIT_ATTEMPT });
    onSubmit && onSubmit(store.values);
    dispatch({ type: Actions.SUBMIT_SUCCESS });
  });

  const isDirty = useMemo(() => !isEqual(store.values, initialValues), [store.values, initialValues]);
  const isValid =
    Object.keys(store.schema_errors).length === 0 && Object.keys(store.touched).length > 0 && Object.keys(store.errors).length === 0;

  return {
    values: store.values,
    errors: store.errors,
    status: store.status,
    touched: store.touched,
    isSubmitting: store.isSubmitting,
    schema_errors: store.schema_errors,
    setValues,
    setErrors,
    setFieldValue,
    setFieldError,
    setIsSubmitting,
    setStatus,
    setTouched,
    setFieldTouched,
    resetField,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
    isDirty,
    isValid,
    fieldRegistryRef,
    registerFieldRef,
  };
};

export default useForm;
