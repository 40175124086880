import HomePage from './pages/home-page';
import ResultsPage from './pages/results-page';
import CountryPage from './pages/country-page';

export interface IRoutes {
  path: string;
  name?: string;
  exact?: boolean;
  component: any;
  id: string;
  routes?: IRoutes[];
}
export const routes: Array<IRoutes> = [
  { path: '/choose-country-region', id: 'country-region', name: 'Choose country or region - Apple', component: CountryPage },
  { path: '/', name: 'Find Locations - Apple', id: 'home-page', exact: true, component: HomePage },
  { path: '/:channel', name: 'Apple - Location Results', id: 'home-page-us', exact: true, component: ResultsPage },
  { path: '/:country/:lang', exact: true, id: 'home-page-locale', name: 'Find Locations - Apple', component: HomePage },
  { path: '/:country/:lang/:channel', id: 'results-page-locale', name: 'Apple - Location Results', component: ResultsPage },
  { path: '*', id: 'redirect', component: HomePage },
];
