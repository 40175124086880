import debounce from 'lodash-es/debounce';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_MAP } from '../../constants';
import { FormProps } from '../../models';
import { trackEvent, decodeHtmlEntities } from '../../services/utility';
import { Button, Dropdown, Input, Selector, SelectorGroup, SelectorLabel } from '../ac-forms';
import Autocomplete from '../ac-forms/autocomplete';
import DropdownOption from '../ac-forms/dropdown/dropdown-option';
import { Values } from '../ac-forms/use-form/models';
import useForm from '../ac-forms/use-form/use-form';
import ActionButton from '../action-button';
import { createCoordinate } from '../maps/map-utility';
import { geocoder } from '../maps/services/geo-coder';
import { Search } from '../maps/services/search';
import SvgIcon from '../svg-icon';
import './form.scss';
import DOMPurify from 'dompurify';

const Form = ({ className, elements, defaultValues, countryCode, lang, onSubmit, onChange, onReset }: FormProps): JSX.Element => {
  const [productsSelected, setProductsSelected] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [locationAccess, setLocationAccess] = useState(true);
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState<Array<{ lines: string[]; coordinate: mapkit.Coordinate }>>([]);
  const [loadSuggestion, setLoadSuggestions] = useState(false);
  const ignoreOnClick = useRef(false);

  const validate = (values: Values) => {
    const errors: any = {};
    if (values && !values.search) {
      errors['search'] = {
        type: 'error',
        description: t('form.location.error.notFound'),
      };
    }
    return errors;
  };

  const getCurrentLocation = (field: string) => {
    setLoader(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const location = await geocoder.reverseGeocoding(createCoordinate(latitude, longitude));
          setFieldValue(field, location?.results[0].formattedAddress);
          setFieldValue('lat', latitude);
          setFieldValue('lon', longitude);
          setLoader(false);
          trackEvent(EVENT_MAP.SEARCH, undefined, undefined, {
            location: 'loc search component',
            link_type: 'user',
            link_text: values.search || location?.results[0].formattedAddress,
          });
        },
        async (error) => {
          setLoader(false);
          setLocationAccess(false);
          console.log(error);
        },
      );
    }
  };

  const getCountryCodeByName = (countryName: string) => {
    const countryId = elements.find((elem) => elem.name === 'country');
    const countryOptions = countryId?.options || [];
    const selectedCountry = countryOptions.find((opt) => opt.name === countryName);
    return selectedCountry ? selectedCountry.code : countryName;
  };

  const formSubmit = async (values: Values) => {
    try {
      setLoader(true);
      const selectedCountryCode = values.country ? getCountryCodeByName(values.country) : countryCode;
      if (values.lat && values.lon) {
        setLoader(false);
        onSubmit({
          ...values,
          latitude: values.lat,
          longitude: values.lon,
          formattedAddress: values.search,
          countryCode: selectedCountryCode,
        });
      } else {
        const resp = await geocoder.forwardGeocoding(values.search, { limitToCountries: selectedCountryCode });
        setLoader(false);
        if (resp && resp.results.length > 0) {
          if (values.lat && values.lon) {
            onSubmit({
              ...values,
              latitude: values.lat,
              longitude: values.lon,
              formattedAddress: values.search,
              countryCode: selectedCountryCode,
            });
          } else {
            const { latitude, longitude } = resp.results[0].coordinate;
            onSubmit({
              ...values,
              latitude,
              longitude,
              formattedAddress: resp.results[0].formattedAddress,
              countryCode: selectedCountryCode,
            });
          }
        } else {
          if (fieldRegistryRef && (fieldRegistryRef as any).current) {
            (fieldRegistryRef as any).current['search']?.focus();
          }
          setFieldError('search', {
            type: 'error',
            description: t('form.location.error.notFound'),
          });
        }
      }
    } catch (ex) {
      setLoader(false);
      onSubmit(values);
      if (fieldRegistryRef && (fieldRegistryRef as any).current) {
        (fieldRegistryRef as any).current['search']?.focus();
      }
      setFieldError('search', {
        type: 'error',
        description: t('form.location.error.notFound'),
      });
    }
  };

  const formState = useForm({
    validate,
    initialValues: {
      search: DOMPurify.sanitize(decodeHtmlEntities(defaultValues?.formattedAddress), { ALLOWED_TAGS: [] }),
      products: defaultValues?.product,
      carrier: defaultValues?.carriers,
      country: defaultValues?.countryCode,
    },
    formConfig: elements,
    onSubmit: (values: any) => formSubmit(values),
  });

  useEffect(() => {
    if (defaultValues?.product) {
      setProductsSelected(true);
    }
  }, []);

  const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'country') {
      const element = elements.find((elem) => elem.name === event.target.name);
      values['country'] && setFieldValue('search', '');
      setFieldValue('carrier', '');
      setFieldValue('products', '');
      setSuggestions([]);
      setProductsSelected(false);
      if (element) {
        onChange(event.target.name, event.target.value, element);
      }
    }
    handleChange(event);
  };

  const handleProductSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductsSelected(true);
    trackEvent(
      EVENT_MAP.CLICK,
      {
        link_owner: 'ac_link',
        type: 'simple link',
        link_text: event.target.value,
      },
      {
        list: [event.target.value],
      },
    );
    handleChange(event);
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setProductsSelected(false);
    setSuggestions([]);
    resetForm();
    onReset && onReset();
    if (fieldRegistryRef && (fieldRegistryRef as any).current) {
      (fieldRegistryRef as any).current['search']?.focus();
    }
    trackEvent(EVENT_MAP.CLICK, { link_owner: 'ac_link', type: 'simple link', link_text: 'reset' });
  };

  const handleMoreProducts = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowMore(!showMore);
    setTimeout(() => {
      const productListRef = document.querySelector(
        'ul.products-container .product-item:nth-child(11) .form-selector-input',
      ) as HTMLInputElement;
      productListRef && productListRef.focus();
    }, 550);
    trackEvent(EVENT_MAP.CLICK, { link_owner: 'ac_link', type: 'simple link', link_text: ' show more products' });
  };

  const {
    values,
    errors,
    isValid,
    handleSubmit,
    resetForm,
    handleBlur,
    setFieldError,
    setFieldValue,
    handleChange,
    registerFieldRef,
    fieldRegistryRef,
  } = formState;

  const handleChangeBtn = (name: string) => {
    setFieldValue(name, '');
    setProductsSelected(false);
    trackEvent(
      EVENT_MAP.CLICK,
      {
        link_owner: 'ac_link',
        name: values[name],
        type: 'simple link',
        link_text: 'change',
      },
      {
        list: [values[name]],
      },
    );
  };

  const handleAutoSelect = (value: any) => {
    const { latitude, longitude } = value.coordinate;
    setFieldValue('search', value.lines[0]);
    setFieldValue('lat', latitude);
    setFieldValue('lon', longitude);
  };

  const mapAutoSearch = (event: any, country: string) => {
    if (event.target.value) {
      setLoadSuggestions(true);
    }
    let selectedLang = lang;
    if (countryCode === 'hk') {
      selectedLang = 'zh-HK';
    }
    if (countryCode === 'mo' || countryCode === 'tw') {
      selectedLang = 'zh-Hant';
    }
    const countrycode = country ? getCountryCodeByName(country) : countryCode;
    const mapSearch = new Search({ getsUserLocation: true, language: selectedLang, limitToCountries: countrycode });
    mapSearch.autoCompleteSearch(event.target.value, (errors, data) => {
      setLoadSuggestions(false);
      if (data) {
        const res = data.results
          .filter((result) => result.coordinate)
          .map((result) => ({ lines: result.displayLines, coordinate: result.coordinate }));
        setSuggestions(res);
      }
      if (errors) {
        setSuggestions([]);
      }
    });
  };

  const debouncedAutoSearch = debounce((event, country) => mapAutoSearch(event, country), 500);

  const getSearchedLocations = (event: any) => {
    debouncedAutoSearch(event, formState.values.country);
    setFieldValue('lat', '');
    setFieldValue('lon', '');
    handleChange(event);
  };

  return (
    <form noValidate className={`form ${className ? className : ''}`}>
      {elements &&
        elements.map((element) => {
          switch (element.type) {
            case 'text': {
              return (
                <Fragment key={element.id}>
                  <div className="row justify-content-center">
                    <div className={element.className}>
                      <Input
                        key={element.id}
                        ref={registerFieldRef}
                        label={t(`${element.label}`)}
                        name={element.name}
                        id={element.id}
                        value={values[element.name]}
                        leftIcon="form-icons-search15"
                        rightIcon={element.geolocation && loader ? 'form-icons-spinner' : ''}
                        btnIcon={element.geolocation && !loader ? 'form-icons-location' : ''}
                        btnTextDisabled={!locationAccess}
                        required={element.required}
                        onTextBtnClick={() => getCurrentLocation(element.name)}
                        message={errors[element.name]}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            }
            case 'autocomplete': {
              return (
                <Fragment key={element.id}>
                  <div className="row justify-content-center">
                    <div className={element.className}>
                      <Autocomplete
                        key={element.id}
                        type="text"
                        ref={registerFieldRef}
                        label={t(`${element.label}`)}
                        loadingLabel={t('loading')}
                        name={element.name}
                        id={element.id}
                        value={values[element.name]}
                        leftIcon="form-icons-search15"
                        rightIcon={element.geolocation && loader ? 'form-icons-spinner' : ''}
                        btnIcon={element.geolocation && !loader ? 'form-icons-location' : ''}
                        btnTextDisabled={!locationAccess}
                        btnAriaLabel={t('form.location.locationAltText')}
                        required={element.required}
                        onTextBtnClick={() => getCurrentLocation(element.name)}
                        message={errors[element.name]}
                        onBlur={handleBlur}
                        onChange={getSearchedLocations}
                        onSelect={handleAutoSelect}
                        suggestions={suggestions}
                        suggestLoader={loadSuggestion}
                        srListStatus={suggestions.length > 0 ? t('form.search.autocompleteResultsText', { count: suggestions.length }) : ''}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            }
            case 'dropdown': {
              return (
                <Fragment key={element.id}>
                  {element.options &&
                  element.options.length > 0 &&
                  element.showOnly &&
                  values[element.showOnly] &&
                  (element.showOnly === 'country' ||
                    values[element.showOnly].toLowerCase() === 'iphone' ||
                    values[element.showOnly].toLowerCase() === 'ipad') ? (
                    <div className="row justify-content-center">
                      <div className={element.className}>
                        <Dropdown
                          key={element.id}
                          ref={registerFieldRef}
                          id={element.id}
                          leftIcon={
                            element.name === 'products'
                              ? values[element.name]
                                ? `form-icons-${values[element.name].split(' ').join('').replace('+', '').toLowerCase()}`
                                : ''
                              : `form-icons-${element.name}`
                          }
                          name={element.name}
                          label={t(`${element.label}`)}
                          selectedValue={values[element.name]}
                          headerText={t(`${element.label}`)}
                          message={errors[element.name]}
                          onBlur={handleBlur}
                          onSelected={handleDropdownChange}>
                          {element.options?.map((option) => (
                            <DropdownOption key={option.id} value={option.value}>
                              {option.value}
                            </DropdownOption>
                          ))}
                        </Dropdown>
                      </div>
                    </div>
                  ) : null}
                  {!element.showOnly && (
                    <div className="row justify-content-center">
                      <div className={element.className}>
                        <Dropdown
                          key={element.id}
                          ref={registerFieldRef}
                          id={element.id}
                          name={element.name}
                          label={t(`${element.label}`)}
                          headerText={t(`${element.label}`)}
                          selectedValue={values[element.name]}
                          message={errors[element.name]}
                          onBlur={handleBlur}
                          leftIcon={
                            values[element.name]
                              ? `form-icons-${
                                  element.name === 'country'
                                    ? element.name
                                    : values[element.name].split(' ').join('').replace('+', '').toLowerCase()
                                }`
                              : ''
                          }
                          onSelected={handleDropdownChange}>
                          {element.options?.map((option) => (
                            <DropdownOption key={option.id} value={option.name}>
                              {option.name}
                            </DropdownOption>
                          ))}
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            }
            case 'multiselect': {
              return (
                <Fragment key={element.id}>
                  <SelectorGroup className="products-container" id={element.id} withGutters>
                    {element.options &&
                      element.options.map((option: any, index: number) => (
                        <Selector
                          style={{ display: index > 9 ? 'none' : '' }}
                          key={index}
                          selected={values[element.name] && values[element.name][option.value]}
                          multiSelect
                          className="product-item"
                          name={`${element.name}[${option.value}]`}
                          id={option.id}
                          value={option.value}
                          onChange={handleChange}>
                          <SelectorLabel htmlFor={option.id}>
                            <SvgIcon className="selector-icon" product={option.value} />
                            <span className="selector-text">{option.value}</span>
                          </SelectorLabel>
                        </Selector>
                      ))}
                  </SelectorGroup>
                </Fragment>
              );
            }
            case 'select': {
              return (
                <Fragment key={element.id}>
                  {(element.options && !element.showOnly) || (element.options && element.showOnly && values[element.showOnly]) ? (
                    <Fragment>
                      {productsSelected && (
                        <div className="row justify-content-center products-input">
                          <div className={element.className}>
                            <Input
                              key={element.id}
                              ref={registerFieldRef}
                              label={t(`${element.label}`)}
                              name={element.name}
                              id={element.id}
                              readonly={true}
                              style={{ pointerEvents: 'none', direction: 'ltr' }}
                              value={values[element.name]}
                              leftIcon={`form-icons-${values[element.name].split(' ').join('').toLowerCase()}`}
                              btnText={t('form.products.buttonText')}
                              required={element.required}
                              onTextBtnClick={() => handleChangeBtn(element.name)}
                              message={errors[element.name]}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}
                      {!productsSelected && (
                        <Fragment>
                          <SelectorGroup
                            role="radiogroup"
                            aria-label={t('form.products.label')}
                            className={`products-container`}
                            id={element.id}
                            withGutters>
                            {element.options &&
                              element.options.map((option: any, index: number) => (
                                <Selector
                                  style={{ display: index > 9 && !showMore ? 'none' : '' }}
                                  key={index}
                                  selected={values[element.name] === option.name}
                                  className="product-item"
                                  name={element.name}
                                  id={option.id}
                                  value={option.name}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    // After focusing on a tile, arrow keys will always trigger an onClick event.
                                    // However, the arrow key down event is detected first. If it is detected, do not process the onClick event.
                                    if (!ignoreOnClick.current) {
                                      handleProductSelected(event);
                                    } else {
                                      ignoreOnClick.current = false;
                                    }
                                  }}
                                  onKeyDown={(event: any) => {
                                    if (event.key === 'Enter') {
                                      handleProductSelected(event);
                                    } else {
                                      ignoreOnClick.current = true;
                                    }
                                  }}>
                                  <SelectorLabel htmlFor={option.id}>
                                    <SvgIcon className="selector-icon" ariaHidden product={option.code} />
                                    <span className="selector-text">{option.name}</span>
                                  </SelectorLabel>
                                </Selector>
                              ))}
                          </SelectorGroup>
                          {element.options && element.options.length > 10 && (
                            <div className="show-more">
                              <ActionButton aria-controls={element.id} aria-expanded={showMore} onClick={handleMoreProducts}>
                                {showMore ? t('form.showLessProducts') : t('form.showMoreProducts')}
                                <span className={`icon icon-after ${!showMore ? 'icon-chevrondown' : 'icon-chevronup'}`}></span>
                              </ActionButton>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Fragment>
              );
            }
            default:
              return <Fragment key={element.id}></Fragment>;
          }
        })}
      <div className="form-actions row justify-content-center">
        <div className="column large-2 medium-3 small-12 small-order-2">
          <Button onClick={handleReset} size="elevated" block color="secondary-alpha">
            {t('form.reset.buttonText')}
          </Button>
        </div>
        <div className="column large-2 medium-3 small-12 small-order-1">
          <Button onClick={handleSubmit} disabled={!isValid || loader} size="elevated" block>
            {t('form.search.buttonText')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
