import axiosInstance from '../services/http-service';
import { Actions, ActionType, GlobalMatrixPayload, SearchPayload } from './model';
import { Widget } from '../models';
import {
  StoreResultsResponse,
  StoreResults,
  Product,
  ServiceType,
  GlobalMatrixResponse,
  Channels,
  Carrier,
  GlobalMatrix,
} from '../models/api-interface';
import { ModifiedStore, StoreResponse } from '../models/store-models';
import sortBy from 'lodash-es/sortBy';
import { ChannelMap } from '../models';
import { MAP_COUNTRY, OTHER_COUNTRIES } from '../constants';
import { createCoordinate } from '../components/maps/map-utility';
import { geocoder } from '../components/maps/services/geo-coder';

const mapChannels = (
  channels: Channels,
  casRedirect: boolean,
  casRedirectUrl: string,
  carriers: Carrier[] = [],
  lang: string,
  countryCode: string,
): ChannelMap => {
  const mappedChannels: ChannelMap = {};
  Object.keys(channels).map((channel) => {
    const widget: Widget = {
      id: channels[channel].channelId,
      name: channels[channel].channelName,
      maxResults: channels[channel].maxResults,
      dropOff: channels[channel].dropOff,
      searchRadius: channels[channel].searchRadius,
      options: {
        products: channels[channel].products || [],
        country: channels[channel].countries || [],
      },
    };
    if (channel === 'service') {
      widget.options.carriers = carriers;
      if (countryCode.toLowerCase() === 'cn') {
        widget.externalLink = casRedirect && `https://getsupport.apple.com/?caller=grl&locale=${lang}_${countryCode}`;
      } else {
        widget.externalLink = casRedirect && casRedirectUrl;
      }
    }
    if (channel === 'consulting') {
      const consultLinks = channels[channel].consultingLinkMap || [];
      const link = consultLinks.filter((items) => items.lang === lang);
      if (link && link.length === 0 && consultLinks.length > 0) {
        widget.externalLink = consultLinks[0].link;
      } else {
        widget.externalLink = link && link[0].link;
      }
    }
    mappedChannels[channel] = widget;
  });
  return mappedChannels;
};

const mapIdToProducts = (results: StoreResults, channel: string): StoreResponse => {
  const { solutionsSpeciality, productMatrixList, stores, badgesArray, repairTypes, vas } = results;
  const mappedStores: ModifiedStore[] = stores.map((store) => {
    let salesProducts: Product[] = [];
    let serviceProducts: Product[] = [];
    let solutionsSpecialties: ServiceType[] = [];
    let serviceTypes: ServiceType[] = [];
    let valueAddedService: ServiceType[] = [];
    if (store.serviceTypes && store.serviceTypes.length > 0) {
      serviceTypes = repairTypes.filter((type) => store.serviceTypes?.includes(type._id.toString()));
    }
    if (store.valueAddedServices && store.valueAddedServices.length > 0) {
      valueAddedService = vas.filter((type) => store.valueAddedServices?.includes(type._id.toString()));
    }
    const storebadges: string[] = [];
    if (store.salesProducts && store.salesProducts.length > 0) {
      salesProducts =
        sortBy(
          productMatrixList.filter((prod) => store.salesProducts.includes(prod._id)),
          'displayOrder',
        ) || [];
    }
    if (store.serviceProducts && store.serviceProducts.length > 0) {
      serviceProducts =
        sortBy(
          productMatrixList.filter((prod) => store.serviceProducts?.includes(prod._id)),
          'displayOrder',
        ) || [];
    }
    if (store.solutionsSpecialties && store.solutionsSpecialties.length > 0) {
      solutionsSpecialties = solutionsSpeciality.filter((solution) => store.solutionsSpecialties?.includes(solution._id.toString())) || [];
    }
    const storeBadges = store.storeBadges || [];
    storeBadges.map((storeBadge) => {
      badgesArray.map((badge) => {
        if (storeBadge === badge.id) {
          if (badge.channels.includes(channel)) {
            storebadges.push(badge.code);
          }
        }
      });
    });
    return {
      ...store,
      salesProducts,
      serviceProducts,
      solutionsSpecialties,
      storeBadges: storebadges,
      serviceTypes: serviceTypes,
      valueAddedServices: valueAddedService,
    };
  });
  return { ...results, stores: mappedStores };
};

const mapOtherCountries = (global: GlobalMatrix[], countryCode: string, countryNativeName: string) => {
  const sortedGlobals = sortBy(global, 'countryName');
  const salesCountry = sortedGlobals
    .filter((country) => country.catchAllSalesCountry)
    .map((country) => {
      return {
        countryCode: country.countryCode,
        countryNativeName: country.countryNativeName,
        countryName: country.countryName,
        products: country.channels['sales'].products,
        carriers: country.carriers,
      };
    });
  const serviceCountry = sortedGlobals
    .filter((country) => country.catchAllServiceCountry)
    .map((country) => {
      return {
        countryCode: country.countryCode,
        countryNativeName: country.countryNativeName,
        countryName: country.countryName,
        products: country.channels['service'].products,
        carriers: country.carriers,
      };
    });
  const globalObj: GlobalMatrix = {
    countryCode,
    countryNativeName,
    countryName: countryNativeName,
    disableMap: false,
    enableGeolocation: true,
    casSupportUrl: global[0].casSupportUrl,
    langs: [
      {
        FooterLang: 'en_ASIA',
        HeaderFooterLang: 'en_ASIA',
        code: 'en',
        countryNativeName: countryNativeName,
        dataLang: 'EN',
        hermesServURL: '',
        hermesStoreURL: '',
        name: 'English',
      },
    ],
    itemsPerPage: 0,
    widgetCount: 0,
    carriers: [],
    channels: {
      sales: {
        channelId: 'sales',
        channelName: 'Sales',
        maxResults: 99,
        searchRadius: 100,
        dropOff: false,
        countries: salesCountry,
      },
      service: {
        channelId: 'service',
        channelName: 'Repairs',
        maxResults: 99,
        searchRadius: 100,
        dropOff: false,
        countries: serviceCountry,
      },
    },
    filterByStoreType: true,
    getDirection: 'https://maps.apple.com/?',
    casRedirect: false,
    checkCountryMismatchArray: global[0].checkCountryMismatchArray,
    dontSendCountryName: global[0].dontSendCountryName,
    disputedCountries: global[0].disputedCountries,
    disputedPlaces: global[0].disputedPlaces,
    pacificisland: global[0].pacificisland,
    images: global[0].images,
    catchAll: false,
    catchAllSalesCountry: false,
    catchAllServiceCountry: false,
  };
  return globalObj;
};

const sendSearchStats = async (
  channel: string,
  country: string,
  lang: string,
  lat: string,
  lon: string,
  productName: string,
  address = '',
): Promise<void> => {
  try {
    const location = await geocoder.reverseGeocoding(createCoordinate(Number(lat), Number(lon)));
    if (location && location.results.length > 0 && location.results[0].countryCode.toLowerCase() === country.toLowerCase()) {
      await axiosInstance.post<any>(`/locationSearch`, {
        channel,
        country,
        locale: lang,
        latitude: lat,
        longitude: lon,
        product: productName,
        searchedLocation: address,
      });
    }
  } catch (ex) {
    console.log('Error while sending the stats');
  }
};

export const fetchResources = async (dispatch: React.Dispatch<ActionType>, { lang, countryCode }: GlobalMatrixPayload): Promise<any> => {
  try {
    dispatch({ type: Actions.LOADING, payload: true });
    const global = await axiosInstance.get<GlobalMatrixResponse>(`/${countryCode}/${lang}/globalmatrix`);
    const modifiedGlobal = OTHER_COUNTRIES.includes(countryCode)
      ? mapOtherCountries(global.data.response, countryCode, MAP_COUNTRY[countryCode])
      : global.data.response[0];
    const { channels, casRedirect, casSupportUrl, carriers } = modifiedGlobal;
    const mappedChannels = mapChannels(channels, casRedirect, casSupportUrl, carriers, lang, countryCode);
    dispatch({ type: Actions.FETCH_RESOURCES, payload: { global: global.data.response[0], channels: mappedChannels } });
  } catch (ex) {
    dispatch({ type: Actions.FETCH_RESOURCES_FAILURE, payload: 'Something went wrong.' });
  } finally {
    dispatch({ type: Actions.LOADING, payload: false });
  }
};

export const populateResources = async (
  dispatch: React.Dispatch<ActionType>,
  global: GlobalMatrix[],
  { lang, countryCode }: GlobalMatrixPayload,
): Promise<any> => {
  try {
    dispatch({ type: Actions.LOADING, payload: true });
    const modifiedGlobal = OTHER_COUNTRIES.includes(countryCode) ? mapOtherCountries(global, countryCode, 'Other Asia') : global[0];
    const { channels, casRedirect, casSupportUrl, carriers } = modifiedGlobal;
    const mappedChannels = mapChannels(channels, casRedirect, casSupportUrl, carriers, lang, countryCode);
    dispatch({ type: Actions.FETCH_RESOURCES, payload: { global: global[0], channels: mappedChannels } });
  } catch (ex) {
    dispatch({ type: Actions.FETCH_RESOURCES_FAILURE, payload: 'Something went wrong.' });
  } finally {
    dispatch({ type: Actions.LOADING, payload: false });
  }
};

export const fetchMapKitToken = async (dispatch: React.Dispatch<ActionType>): Promise<any> => {
  try {
    const token = await axiosInstance.get<GlobalMatrixResponse>(`/mapKitToken?no-cache=${Math.random()}`, {
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0',
      },
    });
    dispatch({ type: Actions.FETCH_MAPKIT_TOKEN, payload: token.data });
  } catch (ex) {
    dispatch({ type: Actions.FETCH_MAPKIT_TOKEN, payload: '' });
  }
};

export const setSearchSubmitted = async (
  dispatch: React.Dispatch<ActionType>,
  {
    lang,
    countryCode,
    channel,
    productId,
    latitude,
    longitude,
    product,
    carriers,
    maxRadius,
    maxResults,
    formattedAddress,
    repairType = '',
  }: SearchPayload,
): Promise<any> => {
  try {
    dispatch({ type: Actions.LOADING_RESULTS, payload: true });
    const queryParams = new URLSearchParams({
      pt: productId,
      lat: latitude.toString(),
      lon: longitude.toString(),
      carrier: carriers || '',
      maxrad: maxRadius?.toString() || '',
      maxResult: maxResults?.toString() || '',
      repairType,
    }).toString();
    const storesApi = await axiosInstance.get<StoreResultsResponse>(`${countryCode.toLowerCase()}/${lang}/${channel}?${queryParams}`);
    const mappedResponse = mapIdToProducts(storesApi.data.results, channel);
    sendSearchStats(channel, countryCode, lang, latitude.toString(), longitude.toString(), product, formattedAddress);
    const isDropOffScreen = window.location.href.indexOf('repairType=Drop') === -1;
    if (isDropOffScreen && mappedResponse.dropOff && channel === 'service') {
      const dropOffStores = mappedResponse.stores.filter((store) => {
        const isDropOffService = store.serviceTypes?.find((type) => type.code === 'rt2');
        if (isDropOffScreen && mappedResponse.dropOff && channel === 'service' && isDropOffService) {
          return false;
        } else {
          return true;
        }
      });
      dropOffStores.map((store, index) => (store.orderNumber = index + 1));
      dispatch({ type: Actions.FETCH_STORE_RESULTS, payload: { ...mappedResponse, stores: dropOffStores } });
    } else {
      dispatch({ type: Actions.FETCH_STORE_RESULTS, payload: mappedResponse });
    }
  } catch (ex) {
    dispatch({ type: Actions.FETCH_STORE_RESULTS_FAILURE, payload: 'Something went wrong.' });
  } finally {
    dispatch({ type: Actions.LOADING_RESULTS, payload: false });
  }
};
