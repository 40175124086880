export class Search {
  search: mapkit.Search;
  reqId: number | undefined;
  constructor(options?: any) {
    this.search = new mapkit.Search(options);
  }

  performSearch(query: string, callback: mapkit.SearchDelegate | mapkit.SearchCallback<string>, options?: mapkit.SearchOptions) {
    if (this.reqId) {
      this.search.cancel(this.reqId);
    }
    query && (this.reqId = this.search.search(query, callback, options));
  }

  autoCompleteSearch(query: string, callback: mapkit.SearchDelegate | mapkit.AutocompleteSearchCallback, options?: mapkit.SearchOptions) {
    if (this.reqId) {
      this.search.cancel(this.reqId);
    }
    query && this.search.autocomplete(query, callback, options);
  }
}
