import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveFilter } from '../../models/api-interface';
import { SelectorGroup } from '../ac-forms';
import Chip from '../chip';
import Icon from '../icon';
import './active-filters.scss';

export interface ActiveFilterProps {
  filters: ActiveFilter[];
  defaultChannel: string;
  selectedProduct: string;
  selectedFilters: string[];
  selectionChanged?: (filter: string) => void;
  filterClosed: () => void;
}

const PRODUCT_FILTERS = ['defaultProducts', 'otherProducts'];

const ActiveFilters = ({
  filters = [],
  defaultChannel,
  selectedProduct,
  selectedFilters = [],
  selectionChanged,
}: ActiveFilterProps): JSX.Element => {
  const [advancedFilters, showAdvancedFilters] = useState(false);
  const advancedFilterRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    selectionChanged && selectionChanged(value);
  };

  const handleMoreFilters = () => {
    showAdvancedFilters(!advancedFilters);
    setTimeout(() => {
      const firstCheckbox = advancedFilterRef.current?.querySelector('input[type="checkbox"]:first-child') as HTMLInputElement;
      firstCheckbox && firstCheckbox.focus();
    }, 550);
  };

  return (
    <div id="active-filters" className="active-filters">
      {filters.length > 0 && (
        <fieldset>
          <legend className="label">{t(`page.results.filters.${defaultChannel}${filters[0].filterId}`)}</legend>
          <SelectorGroup role="presentation" id={filters[0].filterId} withGutters className="row filter-group">
            {filters[0].filterData
              .filter((product) => product.id !== selectedProduct)
              .map((product) => (
                <React.Fragment key={product.id}>
                  <Chip
                    className="filter dir-ltr"
                    id={product.id}
                    selected={selectedFilters.includes(product.code)}
                    name={product.code}
                    multiSelect
                    onChange={() => handleChange(product.code)}>
                    {product.title}
                  </Chip>
                </React.Fragment>
              ))}
            {defaultChannel === 'sales' && (
              <Chip
                className={`filter ${filters[0].filterId}`}
                id="service-for-products"
                selected={selectedFilters.includes('service')}
                name="service"
                multiSelect
                onChange={() => handleChange('service')}>
                {t(`page.results.filters.serviceFilterButton`)}
              </Chip>
            )}
          </SelectorGroup>
        </fieldset>
      )}
      <div ref={advancedFilterRef} id="advanced-filter-options">
        {advancedFilters &&
          filters
            .filter((filter) => !PRODUCT_FILTERS.includes(filter.filterId))
            .filter((filter) => filter.filterData && filter.filterData.length > 0)
            .map((filter) => (
              <fieldset key={filter.filterId}>
                <legend className="label">{t(`page.results.filters.${filter.filterId}Heading`)}</legend>
                <SelectorGroup id={filter.filterId} withGutters className="row filter-group special-filters">
                  {filter.filterData.map((product) => (
                    <React.Fragment key={product.code}>
                      <Chip
                        className={`filter ${filter.filterId}`}
                        id={product.code}
                        selected={selectedFilters.includes(product.code)}
                        name={product.code}
                        multiSelect
                        onChange={() => handleChange(product.code)}>
                        {t(`page.results.filters.${filter.filterId}.${product.code}`)}
                      </Chip>
                    </React.Fragment>
                  ))}
                </SelectorGroup>
              </fieldset>
            ))}
      </div>
      <Icon
        className="link more-filters"
        onClick={handleMoreFilters}
        as="button"
        name={!advancedFilters ? 'chevrondown' : 'chevronup'}
        after
        label={!advancedFilters ? t('page.results.filters.moreFilters') : t('page.results.filters.lessFilters')}
        aria-expanded={advancedFilters ? true : false}
        aria-controls="advanced-filter-options"></Icon>
    </div>
  );
};

export default ActiveFilters;
