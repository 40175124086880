import { Actions } from './enum';
import { setIn, unsetIn } from './utility';
export interface FormState {
  status: { [key: string]: any };
  values: { [key: string]: any };
  errors: { [key: string]: any };
  touched: { [key: string]: any };
  schema_errors: { [key: string]: any };
  isSubmitting: boolean;
}

export interface ActionType {
  type: Actions;
  payload?: any;
}

export const formReducer = (state: FormState, action: ActionType): FormState => {
  switch (action.type) {
    case Actions.SET_FIELD_VALUE:
      return {
        ...state,
        values: setIn(state.values, action.payload.field, action.payload.value),
      };
    case Actions.SET_FIELD_ERROR:
      return {
        ...state,
        errors: setIn(state.errors, action.payload.field, action.payload.value),
      };
    case Actions.SET_FIELD_VALID:
      return {
        ...state,
        errors: unsetIn(state.errors, action.payload.field),
      };
    case Actions.SET_FIELD_TOUCHED:
      return {
        ...state,
        touched: setIn(state.touched, action.payload.field, action.payload.value),
      };
    case Actions.SET_VALUES:
      return {
        ...state,
        values: action.payload,
      };
    case Actions.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case Actions.SUBMIT_ATTEMPT:
      return {
        ...state,
        isSubmitting: true,
      };
    case Actions.SUBMIT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    case Actions.SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case Actions.SCHEMA_ERRORS:
      return {
        ...state,
        schema_errors: action.payload,
      };
    case Actions.RESET:
      return {
        ...state,
        values: action.payload,
      };
    case Actions.RESET_FIELD:
      return {
        ...state,
        values: setIn(state.values, action.payload.field, action.payload.value),
        errors: unsetIn(state.errors, action.payload.field),
      };
    case Actions.SET_ISSUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case Actions.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};
