import React from 'react';
import './selector.scss';
export interface SelectorProps {
  id: string;
  name: string;
  disabled?: boolean;
  selected?: boolean;
  violator?: string;
  singleline?: boolean;
  className?: string;
  threeline?: boolean;
  swatch?: boolean;
  short?: boolean;
  children?: React.ReactNode;
  multiSelect?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

const Selector = ({
  id,
  name,
  disabled = false,
  violator = '',
  selected = false,
  singleline = false,
  threeline = false,
  swatch = false,
  short = false,
  className = '',
  multiSelect = false,
  children,
  onChange,
  onKeyDown,
  ...props
}: SelectorProps): JSX.Element => {
  const classes = [
    'form-selector dir-ltr',
    singleline ? 'form-selector-singleline' : '',
    disabled ? 'form-selector-non-editable' : '',
    threeline ? 'form-selector-threeline' : '',
    swatch ? 'form-selector-swatch' : '',
    violator ? 'form-selector-violator' : '',
    short ? 'form-selector-short' : '',
    className,
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <div className={classes} style={props.style}>
      <input
        className="form-selector-input"
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={name}
        checked={selected}
        id={id}
        disabled={disabled}
        type={multiSelect ? 'checkbox' : 'radio'}
        {...props}
      />
      {children}
    </div>
  );
};

export default Selector;
