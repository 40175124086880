import React from 'react';
import './action-button.scss';
export interface ActionButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  [key: string]: any;
}

const ActionButton = ({ className = '', children, disabled = false, onClick, ...props }: ActionButtonProps): JSX.Element => {
  return (
    <button onClick={onClick} disabled={disabled} className={`action-button ${className ? className : ''}`} {...props}>
      {children}
    </button>
  );
};

export default ActionButton;
