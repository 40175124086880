import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Loader from '../../loader';
import Input, { InputProps } from '../input/input';
import AutoCompleteItem from './auto-complete-item';
import './autocomplete.scss';

export interface AutocompleteProps extends InputProps {
  onSelect: (value: string) => void;
  suggestLoader: boolean;
  suggestions: any[];
  loadingLabel: string;
  srListStatus?: string;
}

const Autocomplete: React.ForwardRefRenderFunction<HTMLInputElement, AutocompleteProps> = (
  {
    type,
    label,
    loadingLabel = 'Loading',
    name,
    id,
    leftIcon,
    rightIcon,
    onBlur,
    onChange,
    onFocus,
    onReset,
    onSelect,
    value = '',
    suggestions = [],
    suggestLoader = false,
    srListStatus = '',
    placeholder,
    ...props
  },
  forwardRef,
): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [cursor, setCursor] = useState(-1);

  const searchContainer = useRef<HTMLDivElement>(null);
  const searchResultRef = useRef<HTMLUListElement>(null);

  const scrollIntoView = (position: number) => {
    if (searchResultRef && searchResultRef.current) {
      (searchResultRef.current as any).parentNode.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    if (suggestions && suggestions.length > 0) {
      setIsOpen(true);
      const srOnlyList = document.querySelector('.status-indicator') as HTMLElement;
      srOnlyList.innerText = srListStatus;
      setTimeout(() => {
        if (srOnlyList) {
          srOnlyList.innerText = '';
        }
      }, 5000);
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestions]);

  useEffect(() => {
    if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
      return;
    }
    if (searchResultRef.current) {
      const listItems = Array.from(searchResultRef.current.children);
      listItems[cursor] && scrollIntoView((listItems[cursor] as any).offsetTop);
    }
  }, [cursor]);

  const showSuggestion = () => setIsOpen(true);
  const hideSuggestion = () => setIsOpen(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (searchContainer.current && !searchContainer.current.contains(event.target as Node)) {
      hideSuggestion();
    }
  };

  const keyboardNavigation = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      isOpen ? setCursor((c) => (c < suggestions.length - 1 ? c + 1 : c)) : showSuggestion();
    }
    if (event.key === 'ArrowUp') {
      setCursor((c) => (c > 0 ? c - 1 : 0));
    }
    if (event.key === 'Escape' || event.key === 'Tab') {
      hideSuggestion();
    }
    if (event.key === 'Enter' && cursor > -1) {
      event.preventDefault();
      event.stopPropagation();
      hideSuggestion();
      onSelect(suggestions[cursor]);
    }
  };

  const selectItem = (item: any) => {
    hideSuggestion();
    onSelect(item);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) {
      setIsOpen(true);
    }
    onFocus && onFocus(event);
  };

  return (
    <div className="ac-suggest" ref={searchContainer}>
      <Input
        type={type}
        label={label}
        name={name}
        id={id}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={handleFocus}
        onReset={onReset}
        onClick={showSuggestion}
        onKeyDown={keyboardNavigation}
        value={value}
        placeholder={placeholder}
        ref={forwardRef}
        aria-label={placeholder}
        role="combobox"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        aria-autocomplete="list"
        aria-owns="quicklinks"
        aria-haspopup="listbox"
        aria-expanded={isOpen && suggestions.length > 0 ? true : false}
        {...props}
      />
      {!suggestLoader && (
        <ul
          id="quicklinks"
          role="listbox"
          className={`suggestions${isOpen && suggestions.length > 0 ? ' visible' : ' invisible'}`}
          ref={searchResultRef}>
          {suggestions.map((suggest, index) => (
            <AutoCompleteItem key={index} onSelectItem={selectItem} item={suggest} isActive={cursor === index ? true : false} />
          ))}
        </ul>
      )}
      {suggestions.length === 0 && suggestLoader && (
        <div role="status" className="suggestions loader">
          <Loader ariaLabel={loadingLabel} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Autocomplete);
