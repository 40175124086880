import React, { forwardRef } from 'react';
import './alert.scss';

export interface AlertType {
  title?: string;
  description: string;
  type?: 'error' | 'info' | 'success';
  customClass?: string;
  children?: React.ReactNode;
}

const Alert: React.ForwardRefRenderFunction<HTMLDivElement, AlertType> = (
  { title, description, type, customClass, children }: AlertType,
  ref,
): JSX.Element => {
  const errorType = type === 'error' ? 'is-error' : '';
  const classes = ['form-alert', customClass || '', errorType].filter(Boolean).join(' ');
  return (
    <div role="alert" data-testid="ac-alert" ref={ref} className={classes}>
      {title && <p className="form-alert-title">{title}</p>}
      {description && <p>{description}</p>}
      {children}
    </div>
  );
};

export default forwardRef(Alert);
