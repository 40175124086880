import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './pagination.scss';

export interface PaginationProps {
  totalRecords: number;
  pageLimit: number;
  onPageChanged: (pageNum: number) => void;
  currentPage: number;
  locale: string;
}

const rangeNum = (start: number, end: number): Array<number> => Array.from({ length: end - start + 1 }, (_, i) => i + start);

const Pagination = ({ totalRecords, pageLimit = 5, onPageChanged, currentPage, locale }: PaginationProps): JSX.Element => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const { totalPages } = getPager(totalRecords, currentPage, pageLimit);
    setTotalPages(totalPages);
  }, [totalRecords]);

  useEffect(() => {
    if (totalPages === currentPage) {
      prevBtnRef.current?.focus();
    }
    if (currentPage === 1) {
      nextBtnRef.current?.focus();
    }
  }, [currentPage]);

  const setPage = (page: number) => {
    const statusRegion = document.querySelector('nav.pagination .visuallyhidden') as HTMLDivElement;
    if (statusRegion) {
      statusRegion.innerText = `page updated, showing results ${(page - 1) * pageLimit + 1} to ${Math.min(
        page * pageLimit,
        totalRecords,
      )} of ${totalRecords}`;
      setTimeout(() => {
        statusRegion.innerText = '';
      }, 4000);
    }
    onPageChanged(page);
  };

  const getPager = (totalItems: number, currentPage = 1, pageSize: number) => {
    const totalPages = Math.ceil(totalItems / pageLimit);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
    const startIndex = (currentPage - 1) * pageLimit;
    const endIndex = Math.min(startIndex + pageLimit - 1, totalItems - 1);
    const pages = rangeNum(startPage, endPage);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  };
  return (
    <Fragment>
      {totalPages > 1 && (
        <nav className="pagination" role="navigation" aria-label="store">
          {currentPage !== 1 && (
            <button
              ref={prevBtnRef}
              onClick={() => setPage(currentPage - 1)}
              className={locale.startsWith('ar-') ? 'icon icon-after icon-paddleright next' : 'icon icon-before icon-paddleleft previous'}>
              {t('page.results.pagination.previous')}
            </button>
          )}
          {currentPage !== totalPages && (
            <button
              ref={nextBtnRef}
              onClick={() => setPage(currentPage + 1)}
              className={locale.startsWith('ar-') ? 'icon icon-before icon-paddleleft previous' : 'icon icon-after icon-paddleright next'}>
              {t('page.results.pagination.next')}
            </button>
          )}
          <div className="visuallyhidden" role="status" aria-live="polite"></div>
        </nav>
      )}
    </Fragment>
  );
};

export default memo(Pagination);
