import React, { useContext, useEffect, useRef } from 'react';
import MapContext from '../map-context';
import { MarkerAnnotation, MarkerAnnotationProps } from '../map-models';
import { createElementFromJSX, mapEventsListeners } from '../map-utility';

const Marker = ({
  coordinate,
  color = '',
  glyphColor = '',
  glyphImage,
  glyphText,
  children,
  hover,
  selected,
  store,
  disableCallout = false,
  srLabel = '',
  ...props
}: MarkerAnnotationProps): JSX.Element => {
  const { map } = useContext(MapContext);
  const events = mapEventsListeners(props);
  const marker = useRef<MarkerAnnotation>();

  const calloutDelegate = {
    calloutElementForAnnotation: function () {
      const {
        salesProducts = [],
        serviceProducts = [],
        storeTypes = [],
        serviceTypes = [],
        solutionsSpecialties = [],
        orderNumber,
      } = store;
      const retailStore = storeTypes.includes(5);
      const max_size =
        (salesProducts.length > 8 || serviceProducts.length > 8) && (serviceTypes.length > 0 || solutionsSpecialties.length > 0);
      const med_size =
        ((salesProducts.length > 6 && salesProducts.length <= 8) || (serviceProducts.length > 6 && serviceProducts.length <= 8)) &&
        (serviceTypes.length > 0 || solutionsSpecialties.length > 0);
      return createElementFromJSX(retailStore, orderNumber, max_size, med_size, children);
    },
    calloutAppearanceAnimationForAnnotation: function () {
      return '.4s cubic-bezier(0.4, 0, 0, 1.5) 0s 1 normal scale-and-fadein';
    },
  };

  useEffect(() => {
    marker.current = new mapkit.MarkerAnnotation(coordinate, {
      color,
      callout: !disableCallout ? calloutDelegate : {},
      glyphColor,
      glyphText,
      glyphImage,
    });
    marker.current.element.innerHTML = `<span class="visuallyhidden">${srLabel}</span>`;
    events && events.map((event: any) => marker.current?.addEventListener(event.type, event.listener));
    map && marker.current && map.addAnnotation(marker.current);
    return () => {
      events.map((event: any) => marker.current?.removeEventListener(event.type, event.listener));
      marker.current && map?.removeAnnotation(marker.current);
    };
  }, [map]);

  useEffect(() => {
    if (marker.current) {
      marker.current.selected = selected ? true : false;
      if (selected) {
        const coords = marker.current.coordinate;
        setTimeout(() => {
          map && map.setCenterAnimated(coords);
        }, 300);
      }
      marker.current.element.className = hover ? 'highlight' : '';
      marker.current.glyphColor = hover ? color : glyphColor;
      marker.current.color = hover && !selected ? glyphColor : color;
    }
  }, [hover, selected]);

  return <></>;
};

export default Marker;
