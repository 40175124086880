import React, { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BADGE_MAP, DIRECTIONS, EVENT_MAP, GEOCODESTATUS } from '../../../constants';
import useKeyPress from '../../../hooks/use-key-press';
import { Product } from '../../../models/api-interface';
import { ModifiedStore } from '../../../models/store-models';
import { trackEvent } from '../../../services/utility';
import { SearchPayload } from '../../../store/model';
import Icon from '../../icon';
import Loader from '../../loader';
import SvgIcon from '../../svg-icon';
import { createCoordinate } from '../map-utility';
import '../map.scss';
import { Directions } from '../services/directions';

interface AddressProps extends ModifiedStore {
  badges?: string[];
  country: string;
  channel: string;
  locale: string;
  getDirection: string;
  isRetailStore: boolean;
  disableMap: boolean;
  casRedirect: boolean;
  selectedProduct: string;
  source: SearchPayload;
  products: Product[];
  casSupportUrl: string;
  directions: Directions;
  totalStores: number;
  address: string;
  mode: string;
  backToList: (event: React.MouseEvent, orderNumber: number) => void;
  closeOnEsc: (event: React.KeyboardEvent, orderNumber: number) => void;
  [key: string]: any;
}

const AddressCard = ({
  title,
  distance,
  city,
  street2,
  phone,
  street1,
  salesProducts = [],
  serviceProducts = [],
  serviceTypes = [],
  solutionsSpecialties = [],
  storeWeb,
  badges = [],
  getDirection,
  latitude,
  longitude,
  postalCode,
  state,
  country,
  isRetailStore,
  channel,
  apptSchedulerIndVal,
  casRedirect,
  disableMap,
  source,
  geoCodeStatus = '',
  storeId,
  locale,
  casSupportUrl,
  products,
  directions,
  selectedProduct,
  orderNumber,
  totalStores,
  address,
  mode,
  backToList,
  closeOnEsc,
}: AddressProps): JSX.Element => {
  const { t } = useTranslation();
  const [etaLoader, setEtaLoader] = useState(false);
  const [travelTime, setTravelTime] = useState<{ hours: number; minutes: number }>();
  const [transportMode, setTransportMode] = useState(mode.toLowerCase());
  const isChina = source.countryCode === 'cn' && source.lang === 'zh';

  const getHttp = (carrierUrl: string) => {
    if (carrierUrl && !/^(f|ht)tps?:\/\//i.test(carrierUrl)) {
      carrierUrl = 'http://' + carrierUrl;
    }
    return carrierUrl;
  };

  const getDirectionsLink = (getDirLink: string, destination: string, lat: number, lng: number) => {
    let dirlink = getDirLink;
    if (lat && lng) {
      dirlink += `saddr=${lat},${lng}`;
    } else {
      dirlink += `saddr=${source.formattedAddress}`;
    }
    if (GEOCODESTATUS.includes(geoCodeStatus)) {
      dirlink += `&daddr=${source.latitude},${source.longitude}&`;
    } else {
      dirlink += `&daddr=${destination}`;
    }
    if (source.lang) {
      dirlink += `&hl=${source.lang}`;
    }
    return dirlink;
  };

  const getReservationRepaired = () => {
    const location = window.location.href;
    const carrierUrl = location.substring(location.indexOf('carrier'), location.length);
    const product = products.find((prod) => prod._id.toString() === selectedProduct);
    const casProd = product ? product.pdmSuperGrpProductId.split(/(\d+)/) : '';
    if (location.indexOf('carrier') > -1) {
      return `${casSupportUrl}${locale}&storeID=${storeId}&lat=${latitude}&long=${longitude}&${carrierUrl}&${
        casProd[0] + '=' + product?.pdmSuperGrpProductId
      }`;
    } else {
      return `${casSupportUrl}${locale}&storeID=${storeId}&lat=${latitude}&long=${longitude}&${
        casProd[0] + '=' + product?.pdmSuperGrpProductId
      }`;
    }
  };

  const sendAnalytics = (event: any) => {
    const product = products.find((prod) => prod._id.toString() === selectedProduct);
    trackEvent(
      EVENT_MAP.SEARCH,
      {
        link_owner: 'ac_link',
        name: 'store map view',
        type: 'simple link',
        link_text: event.target.innerText,
        dest_url: event.target.innerText,
      },
      { list: [product?.displayName] },
      {
        term: address,
        results: `${totalStores}`,
        resultType: 'user',
        store_ID: storeId,
        resultViewType: 'find location results',
        result_index: orderNumber,
      },
    );
  };

  const secondsToHms = (d: number): { hours: number; minutes: number } => {
    const hours = Math.floor(d / 3600);
    const minutes = Math.floor((d % 3600) / 60);
    return { hours, minutes };
  };

  const changeTransportMode = (mode: string) => {
    setTransportMode(mode);
    setEtaLoader(true);
    directions
      .getEta({
        origin: createCoordinate(Number(source.latitude), Number(source.longitude)),
        destinations: [createCoordinate(Number(latitude), Number(longitude))],
        transportType: mode === 'automobile' ? mapkit.Directions.Transport.Automobile : mapkit.Directions.Transport.Walking,
        departureDate: new Date(),
      })
      .then((data) => {
        const { etas } = data;
        if (etas && etas.length > 0) {
          const time = secondsToHms(etas[0].expectedTravelTime);
          setTravelTime(time);
          setEtaLoader(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setEtaLoader(false));
  };

  useEffect(() => {
    const transportType = transportMode === 'automobile' ? mapkit.Directions.Transport.Automobile : mapkit.Directions.Transport.Walking;
    setEtaLoader(true);
    directions
      .getEta({
        origin: createCoordinate(Number(source.latitude), Number(source.longitude)),
        destinations: [createCoordinate(Number(latitude), Number(longitude))],
        transportType: transportType,
        departureDate: new Date(),
      })
      .then((data) => {
        const { etas } = data;
        if (etas && etas.length > 0) {
          const time = secondsToHms(etas[0].expectedTravelTime);
          setTravelTime(time);
          setEtaLoader(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setEtaLoader(false));
  }, []);

  useKeyPress('Escape', (event: any) => {
    closeOnEsc(event, orderNumber);
  });

  useEffect(() => {
    const addressCard: HTMLElement | null = document.getElementById(`store-list-${orderNumber}`);
    if (addressCard) {
      addressCard.setAttribute('tabindex', '-1');
      addressCard.setAttribute('role', 'dialog');
      addressCard.setAttribute('aria-labelledby', 'store-name-id');
      setTimeout(() => addressCard.focus(), 0);
    }
  }, [orderNumber]);

  return (
    <div className="address">
      <div className="address-wrapper">
        <div className={`address-info${isChina ? ' china' : ''}`}>
          <div className="address-title">
            <h2 id="store-name-id" className="visuallyhidden">
              {title}
            </h2>
            <span className="title">
              {storeWeb ? (
                <a id="title-name" target="_blank" rel="noreferrer" href={getHttp(storeWeb)}>
                  {title} <span className="visuallyhidden"> {t('infoWindow.websiteContext')}</span>
                </a>
              ) : (
                <span id="title-name" className="no-link">
                  {title}
                </span>
              )}
            </span>
            {isChina && <span className="distance">{distance}</span>}
          </div>
          <div className="location">
            <div className="location_area">
              <p>{[street1, street2].filter(Boolean).join(', ')}</p>
              {city && <p>{[city, state, postalCode].filter(Boolean).join(', ')}</p>}
              {phone && <p className="dir-ltr">{phone}</p>}
            </div>
          </div>
        </div>
        {!(source.countryCode === 'cn' && source.lang === 'zh') && (
          <div className="driving-distance">
            <div className="travel-time">
              {DIRECTIONS.map((direction) => (
                <button
                  key={direction}
                  aria-label={`Driving distance for ${direction}`}
                  onClick={() => changeTransportMode(direction)}
                  className={`direction${direction === transportMode ? ' active' : ''}`}>
                  <SvgIcon className="transport-icon" product={direction} />
                </button>
              ))}
            </div>
            {etaLoader ? (
              <Loader />
            ) : (
              <div className="address-distance">
                {travelTime?.hours === 0 && (
                  <Fragment>
                    <strong>{travelTime.minutes}</strong>&nbsp;mins
                    <br />
                  </Fragment>
                )}
                {travelTime && travelTime.hours > 0 && (
                  <Fragment>
                    <strong>{travelTime.hours}</strong>&nbsp;hrs&nbsp;<strong>{travelTime.minutes}</strong>&nbsp;mins
                    <br />
                  </Fragment>
                )}
                {`(${distance})`}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="links">
        <div className="all-links">
          {isRetailStore && (
            <Icon
              as="link"
              className={locale.startsWith('ar-') ? '' : 'more'}
              href={getHttp(storeWeb)}
              label={channel === 'sales' ? t('infoWindow.viewStoreDetails') : t('infoWindow.linkGeniusAppointments')}
              after
              onClick={sendAnalytics}
              name={locale.startsWith('ar-') ? 'chevronleft' : 'chevron-right'}
            />
          )}
          {!isRetailStore && (
            <Fragment>
              {!disableMap && (
                <Icon
                  as="link"
                  className={locale.startsWith('ar-') ? '' : 'more'}
                  href={getDirectionsLink(
                    getDirection,
                    `${[street1, street2, city, state, postalCode, country].filter(Boolean).join(',')}`,
                    latitude,
                    longitude,
                  )}
                  label={t('infoWindow.getDirections')}
                  after
                  name={locale.startsWith('ar-') ? 'chevronleft' : 'chevron-right'}
                />
              )}
              {storeWeb && (
                <Icon
                  as="link"
                  target="_blank"
                  href={getHttp(storeWeb)}
                  className={locale.startsWith('ar-') ? '' : 'more'}
                  label={t('infoWindow.websites')}
                  srLabel={t('infoWindow.websiteContext')}
                  after
                  onClick={sendAnalytics}
                  name={locale.startsWith('ar-') ? 'chevronleft' : 'chevron-right'}
                />
              )}
              {country &&
                (country.toLowerCase() === 'in' || country.toLowerCase() === 'cn') &&
                apptSchedulerIndVal &&
                !casRedirect &&
                channel === 'service' &&
                serviceProducts &&
                serviceProducts.length > 0 && (
                  <Icon
                    as="link"
                    className={locale.startsWith('ar-') ? '' : 'more'}
                    href={getReservationRepaired()}
                    label={t('page.results.appointmentSchedulerLabel')}
                    after
                    onClick={sendAnalytics}
                    name={locale.startsWith('ar-') ? 'chevronleft' : 'chevron-right'}
                  />
                )}
            </Fragment>
          )}
        </div>
        <div className="all-badges">
          {badges.map((badge) => (
            <span key={badge} className="badge">
              {BADGE_MAP[badge]}
            </span>
          ))}
        </div>
      </div>
      {!isRetailStore && (
        <div className="products">
          {salesProducts.length > 0 && (
            <div className="sales-products">
              <p className="heading">{t('infoWindow.productSales')}</p>
              <ul className="product-list">
                {salesProducts.map((product) => (
                  <li key={product._id} className="product dir-ltr">
                    {product.displayName}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {serviceProducts.length > 0 && (
            <div className="service-products">
              <p className="heading">{t('infoWindow.productService')}</p>
              <ul className="product-list">
                {serviceProducts.map((product) => (
                  <li key={product._id} className="product dir-ltr">
                    {product.displayName}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {serviceTypes.length > 0 && (
            <div className="service-types">
              <p className="heading">{t('infoWindow.serviceType')}</p>
              <p className="service-mode">
                {serviceTypes.map((service) => (
                  <span className="mode" key={service._id}>
                    {t(`page.results.filters.repairTypes.${service.code}`)}
                  </span>
                ))}
              </p>
            </div>
          )}
          {solutionsSpecialties.length > 0 && (
            <div className="service-types">
              <p className="heading">{t('infoWindow.specialities')}</p>
              <p className="service-mode">
                {solutionsSpecialties.map((service) => (
                  <span className="mode" key={service._id}>
                    {t(`page.results.filters.solutionsSpecialties.${service.code}`)}
                  </span>
                ))}
              </p>
            </div>
          )}
        </div>
      )}
      <button tabIndex={-1} onClick={(event: React.MouseEvent) => backToList(event, orderNumber)} className="visuallyhidden">
        back to list
      </button>
    </div>
  );
};
export default memo(AddressCard);
