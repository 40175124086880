import React, { createContext, useReducer } from 'react';
import { IState, IStoreContext } from './model';
import reducer from './reducer';

const initialState: IState = {
  lang: 'en',
  locale: 'en_US',
  theme: 'light',
  channels: {},
  global: undefined,
  loading: false,
  loading_results: false,
  fetching_results: false,
  results: undefined,
  results_filter: [],
  errors: undefined,
};

const StoreContext = createContext<IStoreContext>({} as IStoreContext);

const StoreProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [store, dispatch] = useReducer(reducer, initialState);
  return <StoreContext.Provider value={{ store, dispatch }}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
export { StoreContext };
