import React, { Fragment } from 'react';
import './chip.scss';

interface ChipProps {
  id: string;
  name: string;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  swatch?: boolean;
  children?: React.ReactNode;
  multiSelect?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

const Chip = ({ id, name, className = '', onChange, selected, disabled, multiSelect, children, ...props }: ChipProps) => {
  return (
    <Fragment>
      <li className={`chip ${className}`}>
        <input
          className="chip-selector-input"
          onChange={onChange}
          name={name}
          checked={selected}
          id={id}
          disabled={disabled}
          type={multiSelect ? 'checkbox' : 'radio'}
          {...props}
        />
        <label className="chip-label" htmlFor={id}>
          {children}
        </label>
      </li>
    </Fragment>
  );
};

export default Chip;
