export enum Actions {
  SET_FIELD_VALUE = 'SET_FIELD_VALUE',
  SET_FIELD_TOUCHED = 'SET_FIELD_TOUCHED',
  SET_FIELD_ERROR = 'SET_FIELD_ERROR',
  SET_FIELD_VALID = 'SET_FIELD_VALID',
  SET_ERRORS = 'SET_ERRORS',
  SET_TOUCHED = 'SET_TOUCHED',
  SET_VALUES = 'SET_VALUES',
  SET_STATUS = 'SET_STATUS',
  SET_ISSUBMITTING = 'SET_ISSUBMITTING',
  SUBMIT_ATTEMPT = 'SUBMIT_ATTEMPT',
  SUBMIT_FAILURE = 'SUBMIT_FAILURE',
  SUBMIT_SUCCESS = 'SUBMIT_SUCCESS',
  RESET = 'RESET',
  SCHEMA_ERRORS = 'SCHEMA_ERRORS',
  RESET_FIELD = 'RESET_FIELD',
}
