import { useEffect, useRef, useState } from 'react';
import MapContext from './map-context';
import { MapProps, MapType } from './map-models';
import { createCoordinate, createPadding } from './map-utility';
import './map.scss';

function colorSchemeForMode() {
  const { matchMedia } = window;
  const isDark = matchMedia && matchMedia('(prefers-color-scheme: dark)').matches;
  return isDark ? mapkit.Map.ColorSchemes.Dark : mapkit.Map.ColorSchemes.Light;
}

const Map = ({ adjustMap, children, zoomLevel = 14, colorScheme, center, ...props }: MapProps): JSX.Element => {
  const [ready, setReady] = useState(false);
  const [map, setMap] = useState<MapType>();
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    colorScheme = colorScheme || colorSchemeForMode();
    const centerCoord = center && createCoordinate(center.latitude, center.longitude);
    if (mapRef && mapRef.current) {
      const newMap = new mapkit.Map(mapRef.current, { ...props, center: centerCoord, colorScheme: colorScheme });
      if (zoomLevel && newMap) {
        (newMap as any)._impl.zoomLevel = zoomLevel;
      }
      setMap(newMap);
      newMap && setReady(true);
    }
  }, []);

  useEffect(() => {
    if (map && adjustMap) {
      const annotations = map.annotations;
      map.showItems(annotations, { animate: true, padding: createPadding(50) });
    }
  }, [adjustMap]);

  return (
    <div className="mapkitjs-map" ref={mapRef} style={{ width: '100%', height: '100%' }}>
      {ready && children && <MapContext.Provider value={{ map: map, isInProvider: true }}>{children}</MapContext.Provider>}
    </div>
  );
};

Map.init = false;
export default Map;
