import React, { Fragment } from 'react';
import './simple-dropdown.scss';

interface DropdownProps {
  id: string;
  name?: string;
  className?: string;
  value?: any;
  label: string;
  options: any;
  onSelected?: (value: any) => void;
  [key: string]: any;
}

const SimpleDropdown = ({ id, label, className, value, name, options, onSelected, ...props }: DropdownProps): JSX.Element => {
  return (
    <Fragment>
      <label htmlFor={id} className="visuallyhidden">
        {label}
      </label>
      <select
        value={value}
        id={id}
        onBlur={onSelected}
        className={`simple-dropdown${className ? ' ' + className : ''}`}
        name={name}
        {...props}>
        {options.map((option: any, index: number) => (
          <option key={index} value={option} className="option">
            {option}
          </option>
        ))}
      </select>
    </Fragment>
  );
};

export default SimpleDropdown;
