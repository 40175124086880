import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { EVENT_MAP } from '../../constants';
import { Lang } from '../../models/api-interface';
import { trackEvent } from '../../services/utility';
import './locale-selector.scss';

interface LocaleSelectorProps {
  langs: Lang[];
  countryCode: string;
  onLocaleChange?: (lang: Lang) => void;
}

const LocaleSelector = ({ langs, countryCode }: LocaleSelectorProps): JSX.Element => {
  const { lang: selectedLang, channel } = useParams<any>();
  const history = useHistory();
  const { t } = useTranslation();

  const getLocalePath = (code: string) => {
    const search = history.location.search;
    if (search) {
      return `/${countryCode}/${code}/${channel}/${search}`;
    } else if (channel) {
      return `/${countryCode}/${code}/${channel}/`;
    } else {
      return `/${countryCode}/${code}/`;
    }
  };

  const handleClick = (event: any) => {
    trackEvent(EVENT_MAP.CLICK, {
      link_owner: 'ac_link',
      name: 'choose your language',
      type: 'simple link',
      link_text: event.target.innerText,
      dest_url: event.target.href,
    });
  };
  const handleKeyUp = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
      trackEvent(EVENT_MAP.CLICK, {
        link_owner: 'ac_link',
        name: 'choose your language',
        type: 'simple link',
        link_text: (event.target as HTMLAnchorElement).innerText,
        dest_url: (event.target as HTMLAnchorElement).href,
      });
      window.location.href = (event.target as HTMLAnchorElement).href;
      window.location.reload();
    }
  };

  return (
    <div className="country-selector">
      <p lang={selectedLang} className="header">
        {t('page.chooseLangText')}
      </p>
      <p className="selector">
        {langs.map((item) => (
          <a
            key={item.code}
            role="button"
            lang={item.code}
            onClick={handleClick}
            onKeyUp={handleKeyUp}
            tabIndex={selectedLang === item.code ? -1 : 0}
            aria-disabled={selectedLang === item.code ? true : false}
            className={selectedLang === item.code ? 'selected' : 'not-selected'}
            href={getLocalePath(item.code)}>
            {item.name} <span aria-hidden="true"></span>
          </a>
        ))}
      </p>
    </div>
  );
};

export default memo(LocaleSelector);
